import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
    return (
        <React.Fragment>
            <div style={{ minHeight: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection:"column" }}>
                <h1>Page not found</h1>

                <Link to='/'>
                    <h4>Goto Home page</h4>
                </Link>
            </div>
        </React.Fragment >
    )
}

export default ErrorPage