import React, { useContext, useEffect, useState } from "react"
import StateContext from "../../../StateContext"
import Hero from "./Hero/Hero"
import ContactUs from "./ContactUs/ContactUs"
import CTA2 from "../../CTA2/CTA2"
import Loader from "../../Loader/Loader"
import Page from "../Page"
function ContactComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [hero, setHero] = useState()
  const [alternate, setAlternate] = useState()
  const [cta2, setCta2] = useState()

  useEffect(() => {
    if (appState?.contactPage?.data) {
      appState?.contactPage?.data?.map((item, i) => {
        switch (item.id) {
          case 24:
            setHero(item)
            return
          case 5:
            setCta2(item)
            return
          default:
            return
        }
      })
      setLoading(false)
    }
  }, [appState])

  if (loading) {
    return <Loader />
  }

  return (
    <main className="page-contact">
      <Page title="Contact Us">
        <Hero data={hero} />
        <ContactUs data={hero} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default ContactComponent
