import React, { useState } from "react"
import { useImmerReducer } from "use-immer"
import { Modal } from "react-bootstrap"
import { FileUploader } from "react-drag-drop-files"
import deleteIcon from "../../../Assets/images/ant-design_delete-filled.png"
import successIcon from "../../../Assets/images/job-success.png"
import axios from "axios"
import { useEffect } from "react"
import { HashLink } from "react-router-hash-link"
import CookiesJS from 'js-cookie'

import PhoneInput from "react-phone-number-input"
import flags from "react-phone-number-input/flags"
import CookiePopup from "../../CookiePolicy/CookiePopup"
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom"
const fileTypes = ["JPG", "PNG", "GIF", "PDF"]


function Applyform({ showForm, setShowForm }) {
  const [submitCount, setSubmitCount] = useState(0)
  const [phone, setPhone] = useState("")
  const [file, setFile] = useState("null")
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [show, setShow] = useState(false)
  const [spinner, setSpinner] = useState(false)

  const handleChange = (file) => {
    setFile(file)
    dispatch({ type: "resumeChange", value: file })
  }

  const initialState = {
    firstName: {
      label: "First Name",
      value: "",
      errorMessage: "",
    },
    lastName: {
      label: "Last Name",
      value: "",
      errorMessage: "",
    },
    email: {
      label: "Email",
      value: "",
      errorMessage: "",
    },
    phone: {
      label: "Phone",
      value: "",
      errorMessage: "",
    },
    address: {
      label: "Address",
      value: "",
      errorMessage: "",
    },
    education: {
      label: "Education",
      value: "",
      errorMessage: "",
    },
    experience: {
      label: "Experience",
      value: "",
      errorMessage: "",
    },
    resume: {
      label: "Resume",
      value: "",
      errorMessage: "",
    },
  }

  function ourReducer(draft, action) {
    switch (action.type) {
      case "firstNameChange":
        draft.firstName.errorMessage = ""
        if (/\d/.test(action.value)) {
          draft.firstName.errorMessage = "Name must not contain a number"
        }
        else if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]+/.test(action.value)) {
          draft.firstName.errorMessage = "Special charaters are not allowed"
        }
        draft.firstName.value = action.value
        return
      case "lastNameChange":
        draft.lastName.errorMessage = ""
        if (/\d/.test(action.value)) {
          draft.lastName.errorMessage = "Name must not contain a number"
        }
        else if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]+/.test(action.value)) {
          draft.lastName.errorMessage = "Special charaters are not allowed"
        }
        draft.lastName.value = action.value
        return
      case "emailChange":
        draft.email.errorMessage = ""
        draft.email.value = action.value
        return

      case "validateEmail":
        if (!/^\S+@\S+$/.test(action.value)) {
          draft.email.errorMessage = "Invalid email format"
        }
        return
      case "phoneChange":
        draft.phone.errorMessage = ""
        if (action.value?.length > 14) {
          draft.phone.errorMessage =
            "Phone number must not be more than 10 characters"
        }
        // if (!/\d/.test(action.value)) {
        //   draft.phone.errorMessage = "Phone number must not contain an aphabet"
        // }
        // if (action.value.length > 10) {
        //   draft.phone.errorMessage =
        //     "Phone number must not contain more than 10 digits"
        // }
        draft.phone.value = action.value
        return
      case "addressChange":
        draft.address.errorMessage = ""
        draft.address.value = action.value
        return
      case "educationChange":
        draft.education.errorMessage = ""
        draft.education.value = action.value
        return

      case "experienceChange":
        draft.experience.errorMessage = ""
        draft.experience.value = action.value
        return

      case "resumeChange":
        draft.resume.errorMessage = ""
        draft.resume.value = action.value
        return
      case "messageChange":
        draft.message.errorMessage = ""
        draft.message.value = action.value
        return
      case "vallidateFields":
        for (const key in draft) {
          if (
            key === "education" ||
            key === "experience" ||
            key === "address"
          ) {
          } else {
            if (!draft[key].value) {
              draft[key].errorMessage = `${draft?.[key]?.label} is required`
            }
          }
        }
        let errorCount = 0
        for (const key in draft) {
          if (draft[key].errorMessage) {
            errorCount++
          }
        }
        if (!errorCount) {
          setSubmitCount(submitCount + 1)
        }

        return
      case "clearForm":
        for (const key in draft) {
          draft[key].value = ""
        }
        setPhone("")
        return
      default:
        return
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState)

  //trigger a dispatch when a phone value changes

  useEffect(() => {
    dispatch({ type: "phoneChange", value: phone })
  }, [phone])

  useEffect(() => {
    if (submitCount) {
      async function submitForm() {
        setLoading(true)
        setSpinner(true)

        let data = {
          FirstName: state.firstName.value,
          LastName: state.lastName.value,
          Email: state.email.value,
          Address: state.address.value,
          PhoneNumber: state.phone.value,
          Education: state.education.value,
          Experience: state.experience.value,
          job_post: 1,
        }
        let formData = new FormData()
        formData.append("files.Resume", file)
        formData.append("data", JSON.stringify(data))
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/job-applications`,
            formData,
            config
          )
          setLoading(false)
          setSuccess(true)
          setShowForm(false)
          setSubmitted(true)
          setSpinner(false)
          dispatch({ type: "clearForm" })
        } catch (e) {
          setLoading(false)
          setError(true)
        }
      }
      submitForm()
    }
  }, [submitCount])

  function handleSubmit(e) {
    e.preventDefault()
    if (CookiesJS.get('ALLACCEPTEDCOOKIES') === "true") {
      dispatch({ type: "vallidateFields" })
    }
    else {
      setShow(true)
    }
  }

  if (!submitted) {
    return (
      <Modal
        size="xl"
        show={showForm}
        onHide={() => setShowForm(false)}
        dialogClassName="modal-70w modal-70w1"
        aria-labelledby="apply-now"
        centered
        className="apply-form"
      >
        <Modal.Header closeButton>
          <Modal.Title id="apply-now">
            <div className="header-box">
              <h2> Apply Now </h2>
              <h4>Personal Information</h4>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form className="form" onSubmit={handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={`form-item ${state.firstName.errorMessage ? "field-error" : ""
                      }`}
                  >
                    <label htmlFor="">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      onChange={(e) =>
                        dispatch({
                          type: "firstNameChange",
                          value: e.target.value,
                        })
                      }
                      value={state.firstName.value}
                    />
                    {state.firstName.errorMessage && (
                      <p className="error">{state.firstName.errorMessage}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={`form-item ${state.lastName.errorMessage ? "field-error" : ""
                      }`}
                  >
                    <label htmlFor="">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      onChange={(e) =>
                        dispatch({
                          type: "lastNameChange",
                          value: e.target.value,
                        })
                      }
                      value={state.lastName.value}
                    />
                    {state.lastName.errorMessage && (
                      <p className="error">{state.lastName.errorMessage}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div
                    className={`form-item ${state.email.errorMessage ? "field-error" : ""
                      }`}
                  >
                    <label htmlFor="">Please enter you email ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email ID Here"
                      onChange={(e) =>
                        dispatch({
                          type: "emailChange",
                          value: e.target.value,
                        })
                      }
                      onBlur={(e) =>
                        dispatch({
                          type: "validateEmail",
                          value: e.target.value,
                        })
                      }
                      value={state.email.value}
                    />
                    {state.email.errorMessage && (
                      <p className="error">{state.email.errorMessage}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div
                    className={`form-item ${state.phone.errorMessage ? "field-error" : ""
                      }`}
                  >
                    <label htmlFor="">Please enter your phone number</label>
                    <div className="phone-number">
                      {/* <select name="" id="" className="form-control">
                        <option value="1">1</option>
                      </select>

                      <input
                        type="number"
                        className="form-control"
                        placeholder="Phone Number Here"
                        onChange={(e) =>
                          dispatch({
                            type: "phoneChange",
                            value: e.target.value,
                          })
                        }
                        value={state.phone.value}
                      /> */}
                      <PhoneInput
                        placeholder="Enter phone number"
                        flags={flags}
                        value={phone}
                        onChange={setPhone}
                        defaultCountry="US"
                      />
                    </div>

                    {state.phone.errorMessage && (
                      <p className="error">{state.phone.errorMessage}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div
                    className={`form-item ${state.address.errorMessage ? "field-error" : ""
                      }`}
                  >
                    <label htmlFor="">Address (Optional)</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      onChange={(e) =>
                        dispatch({
                          type: "addressChange",
                          value: e.target.value,
                        })
                      }
                      value={state.address.value}
                    />
                    {state.address.errorMessage && (
                      <p className="error">{state.address.errorMessage}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <h3 className="text-bold">Profile Information</h3>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex justify-content-end align-items-center">
                    <span
                      className="icon-container"
                      onClick={() => dispatch({ type: "clearForm" })}
                    >
                      <img src={deleteIcon} alt="" />{" "}
                    </span>
                    <span>Clear</span>
                  </div>
                </div>
                <div className="col">
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className={`form-item ${state.education.errorMessage ? "field-error" : ""
                      }`}
                  >
                    <label htmlFor="">Education (Optional)</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Education"
                      onChange={(e) =>
                        dispatch({
                          type: "educationChange",
                          value: e.target.value,
                        })
                      }
                      value={state.education.value}
                    />
                    {state.education.errorMessage && (
                      <p className="error">{state.education.errorMessage}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className={`form-item ${state.experience.errorMessage ? "field-error" : ""
                      }`}
                  >
                    <label htmlFor="">Experience (Optional)</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Experience"
                      onChange={(e) =>
                        dispatch({
                          type: "experienceChange",
                          value: e.target.value,
                        })
                      }
                      value={state.experience.value}
                    />
                    {state.experience.errorMessage && (
                      <p className="error">{state.experience.errorMessage}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-10">
                  <p className=""> Resume</p>
                </div>
              </div>
              <div
                className={`form-item ${state.resume.errorMessage ? "field-error" : ""
                  }`}
              >
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  placeholder="Upload a file or drag and drop here"
                />
                {state.resume.errorMessage && (
                  <p className="error">{state.resume.errorMessage}</p>
                )}
              </div>
            </div>
            <div class="button-container w-100">
              <span class="overlay"></span>

              <button class="btn btn-primary w-100" disabled={loading}>
                {
                  spinner && <Spinner animation="border" size="lg" />
                }
                Submit Application
              </button>
            </div>
          </form>
          {
            show &&
            <CookiePopup
              showCookiePolicy={show}
              setShowCookiePolicy={setShow}
            />
          }
        </Modal.Body>
      </Modal>
    )
  }
  return (
    <Modal
      size="lg"
      show={setSubmitted}
      onHide={() => setSubmitted(false)}
      dialogClassName="modal-70w modal-small"
      aria-labelledby="apply-now"
      className="apply-form"
    >
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <div className="image-container">
          <img src={successIcon} alt="" />
        </div>
        <h4 className="jobappliesuccessheader">Job applied successfully!</h4>
        <p className="text-muted textmutesize">
          Please check you email ID for further steps.
        </p>
        <div class="button-container" style={{ width: "15rem" }}>
          <span class="overlay"></span>
          <Link
            class="btn btn-primary w-100"
            onClick={() => setSubmitted(false)}
            to="/career#team">
            Done
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Applyform
