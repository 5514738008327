import React from "react"
import image from "../../../../Assets/images/bg-demo.jpg"
// import videoPlaceholder from "../../../../Assets/images/video-placeholder.jpg"
function Video(props) {
  return (
    <div
      className="sec-vid"
      style={{
        backgroundImage: `url(${props.data?.attributes?.Image?.data &&
            props.data?.attributes?.Image?.data[0]?.attributes?.url
            ? props.data?.attributes?.Image?.data &&
            props.data?.attributes?.Image?.data[0]?.attributes?.url
            : image
          })`,
      }}
    >
      <div className="content-container">
        <div className="header-container">
          <div className="header-box">
            <h1>Regulate</h1>
            <h2> {props.data?.attributes?.Title}</h2>
          </div>
          <div className="text-box">
            <p className="subtitle">{props.data?.attributes?.Description}</p>
          </div>
        </div>
        <div className="video-inner-container">
          <div className="iframe-container">
            <iframe
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="788.54"
              height="443"
              type="text/html"
              allow="fullscreen"
              src={`${props.data?.attributes?.Image?.data[1]?.attributes?.url}`}
            >
              <div>
                <small>
                  <a href="https://youtubeembedcode.com/es/">
                    youtubeembedcode es
                  </a>
                </small>
              </div>
              <div>
                <small>
                  <a href="https://casinoutankonto.online/">
                    https://casinoutankonto.online
                  </a>
                </small>
              </div>
              <div>
                <small>
                  <a href="https://youtubeembedcode.com/en">
                    youtubeembedcode.com/en/
                  </a>
                </small>
              </div>
              <div>
                <small>
                  <a href="https://skattefria-casinon.com/">
                    https://skattefria-casinon.com/
                  </a>
                </small>
              </div>
              <div>
                <small>
                  <a href="https://youtubeembedcode.com/nl/">
                    youtubeembedcode nl
                  </a>
                </small>
              </div>
              <div>
                <small>
                  <a href="https://dinocasino.se/">https://dinocasino.se</a>
                </small>
              </div>
            </iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Video
