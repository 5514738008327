import React from "react"
import herobg from "../../../../Assets/images/hero2.jpg"
function Hero(props) {
  return (
    <section
      className="section-hero"
      style={{
        backgroundImage: `url(${
          props.data?.attributes?.Image?.data &&
          props.data?.attributes?.Image?.data[0]?.attributes?.url
        })`,
      }}
    >
      <div className="container">
        <div className="text-box">
          <h1> {props.data?.attributes?.Title}</h1>
          <p>{props.data?.attributes?.Description}</p>
        </div>
      </div>
    </section>
  )
}

export default Hero
