import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import CheckIcon from "../../../../Assets/images/check.png"
import tag from "../../../../Assets/images/tag.png"
function CardOffer(props) {
  const [sorted, setSorted] = useState()

  useEffect(() => {
    let sortedArray = props.data
    // sortedArray.sort(function (a, b) {
    //   // return parseInt(a.id) - parseInt(b.id)
    // })

    setSorted()
    // props.data.sort((a, b) => {
    //   return a.attributes.ordering - b.attributes.ordering
    // })
  }, [])
  return (
    <section className="section-card-offer">
      <div className="container">
        <div className="row">
          {props.data?.map((item, i) => {
            let list = item.attributes.SubDescription.split("-")
            //   typeof item.attributes.SubDescription,
            //   item.attributes.SubDescription
            // )
            return (
              <div className="col-lg-4 col-md-6" key={i}>
                <div className="content-container basic">
                  <div className="tag-container">
                    <img src={tag} alt="" />
                  </div>
                  <p className="package">{item.attributes.Title}</p>
                  <h1 className="price">{item.attributes.SubTitle}</h1>
                  <p className="text-muted subtitle">
                    {item.attributes.Description}
                  </p>
                  <div className="button-container">
                    <span className="overlay"></span>
                    <a
                      href={item.attributes.Url}
                      className="btn btn-primary w-100"
                    >
                      Try for free
                    </a>
                  </div>
                  <div className="features-container">
                    <div className="feature-header d-flex">
                      <div className="icon-container">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.54981 16.125C12.4849 16.125 15.6748 12.9351 15.6748 9C15.6748 5.06486 12.4849 1.875 8.54981 1.875C4.61467 1.875 1.4248 5.06486 1.4248 9C1.4248 12.9351 4.61467 16.125 8.54981 16.125ZM12.3766 6.74494C12.41 6.7106 12.436 6.6699 12.4533 6.62526C12.4706 6.58063 12.4786 6.53297 12.4771 6.48514C12.4755 6.43731 12.4643 6.39029 12.4442 6.34688C12.424 6.30347 12.3954 6.26456 12.3599 6.23247C12.3244 6.20039 12.2827 6.17579 12.2375 6.16014C12.1923 6.14449 12.1444 6.13811 12.0966 6.14139C12.0489 6.14466 12.0023 6.15752 11.9596 6.1792C11.917 6.20088 11.8791 6.23093 11.8483 6.26756L7.58081 10.9832L5.23312 8.74207C5.16481 8.67679 5.07336 8.64131 4.97889 8.64345C4.88442 8.64559 4.79467 8.68516 4.72938 8.75348C4.66409 8.82179 4.62861 8.91324 4.63075 9.00771C4.63289 9.10217 4.67247 9.19193 4.74078 9.25721L7.35352 11.751L7.61821 12.0039L7.86367 11.7324L12.3763 6.74494H12.3766Z"
                          />
                        </svg>
                      </div>
                      <p className="text-highlight">Plan Features</p>
                    </div>
                    <ul>
                      {list?.map((item, i) => {
                        return <li key={i}>{item}</li>
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default CardOffer
