import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
function BlogPosts(props) {
  const [numOfItemsToShow, setNumOfItemsToShow] = useState(5)
  const [currentList, setCurrentList] = useState([])
  const [trylist, setTrylist] = useState([])
  const [endOfList, setEndOfList] = useState(false)

  //set pagination
  useEffect(() => {
    const newArray = []
    props.data?.attributes?.section_contents?.data?.map((item, i) => {
      if (i < numOfItemsToShow) {
        newArray.push(item)
      }
    })    
    setCurrentList(newArray?.filter((item) => item.id != 76))
    setTrylist(newArray?.filter((item) => item.id == 76))
    if (currentList?.length > 1) {
      setEndOfList(true)
    } else {
      setEndOfList(false)
    }
  }, [numOfItemsToShow, props])

  return (
    <>
      <section className="section-main">
        <div className="container">
          <div className="header-box">
            <h1>{props.data?.attributes?.Title}</h1>
          </div>
          <div className="row">
            {currentList?.map((item, i) => {
              const date = new Date(item.attributes.updatedAt)
              return (
                <div className="col-12">
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/blogs/${item.id}`}
                    key={i}
                  >
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="image-container">
                          <img
                            src={
                              item?.attributes?.SectionImage?.data &&
                              item?.attributes?.SectionImage?.data?.attributes
                                ?.url
                            }
                            alt=""
                          />
                        </div>
                      </div>

                      <div className="col-lg-5">
                        <div className="content-container">
                          {/* <p className="text-muted">Subtitle </p> */}
                          <div className="header-box">
                            <h2>{item?.attributes?.Title}</h2>
                          </div>
                          <div className="text-box">
                            <p className="subitle">
                              {item?.attributes?.SubDescription.substr(
                                0,
                                200
                              )}{" "}
                              ...
                            </p>
                          </div>
                          <p className="text-muted date">
                            {monthNames[date.getMonth()]} {date.getDate()},{" "}
                            {date.getFullYear()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <section className="section-news card-container">
        <div className="container">
          <div>
            {
              trylist?.map((item, i) => {
                return (
                  <div className="col-lg-4">
                    <div className="content-container dh-cta">
                      <div className="header-box">
                        <h2>
                          {item.attributes.Title}
                          <div className="image-container" style={{ marginBottom: '12px', width: 200 }}>
                            <img
                              src={
                                item.attributes.SectionImage.data?.attributes
                                  ?.url
                              }
                              alt=""
                            />
                          </div>
                          {item.attributes?.SubTitle}!
                        </h2>
                      </div>
                      <p style={{ textAlign: "center" }}>{item.attributes?.Description}</p>

                      <div className="button-container w-100">
                        <span className="overlay"></span>
                        <a
                          href="https://dnh-prod.netlify.app/#/auth/sign-up"
                          className="btn btn-transparent w-100"
                        >
                          Try For Free
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

        </div>
        <div className="text-box bg-white text-center loadmore ">
          {endOfList && (
            <p
              className="label-dark"
              onClick={() => setNumOfItemsToShow(numOfItemsToShow + 5)}
            >
              Load More Blogs
            </p>
          )}
        </div>
      </section>
    </>
  )
}

export default BlogPosts

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
