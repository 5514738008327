import React from "react"
import { useLocation, useParams, Link } from "react-router-dom"
import StateContext from "../../../StateContext"
import image from "../../../Assets/images/blog-main.jpg"
import icon1 from "../../../Assets/images/fb-muted.png"
import image5 from "../../../Assets/images/blog5.jpg"
import image6 from "../../../Assets/images/blog6.jpg"
import image7 from "../../../Assets/images/blog7.jpg"
import CTA2 from "../../CTA2/CTA2"
import { useEffect } from "react"
import { useContext, useState } from "react"
import Loader from "../../Loader/Loader"
import SuggestedPosts from "./SuggestedPosts/SuggestedPosts"
import Page from "../Page"
import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"
import axios from "axios"

const BlogDetail = () => {

  const [loader, setLoader] = useState(false)
  const [appState, setAppState] = useState(false)
  // const appState = useContext(StateContext)

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}[$contains]=Blog`).then((res) =>
      setAppState(res)
    )
  }, [])

  if (!loader) {
    <Loader />
  }
  return (
    appState ?
      <MainBlog appState={appState} />
      :
      <Loader />
  )
}

function MainBlog({ appState }) {
  const { id } = useParams()
  const location = useLocation(StateContext)
  const [blogPost, setBlogPost] = useState(null)
  const [suggestedPosts, setSuggestedPosts] = useState()
  const [cta2, setCta2] = useState()
  const [date, setDate] = useState()

  useEffect(() => {
    const blogposts = appState?.data?.data.filter((el) => el.id === 14)
    setBlogPost(
      blogposts[0]?.attributes?.section_contents?.data?.filter(
        (el) => el.id == id
      )
    )
    setSuggestedPosts(blogposts[0]?.attributes?.section_contents?.data)
    // setDate(new Date(blogPost.attributes.updatedAt))

    setDate(new Date(blogPost?.attributes?.updatedAt))
  }, [location.pathname])

  useEffect(() => {
    if (blogPost) {
      setDate(new Date(blogPost[0]?.attributes?.updatedAt))
    }
  }, [blogPost])

  useEffect(() => {
    appState.data?.data.map((item, i) => {
      switch (item.id) {
        case 5:
          setCta2(item)
          return
        default:
          return
      }
    })
  }, [appState])

  if (!blogPost) {
    return <Loader />
  }

  return (
    <Page title={`Blog Post`}>
      <main className="page-blog-details">
        <div className="container">
          <p class="page-navigation">
            <Link to="/blogs">Blogs</Link>{" "}
            <span>/ {blogPost[0]?.attributes?.Title}</span>
          </p>
        </div>
        <section className="section-blog-detail-main">
          <div className="container">
            <div className="header-box">
              {/* <p className="text-muted">{blogPost[0]?.attributes?.SubTitle}</p> */}
              <h2>{blogPost[0]?.attributes?.Title}</h2>
              <p className="text-muted">
                {monthNames[date.getMonth()]} {date.getDate()},{" "}
                {date.getFullYear()}
              </p>
            </div>
            <div className="blog-content">
              <div className="container container-narrow">
                <div className="image-container image-container-main">
                  <img
                    style={{ borderRadius: "15px" }}
                    src={
                      blogPost[0]?.attributes?.SectionImage?.data &&
                      blogPost[0]?.attributes?.SectionImage?.data?.attributes
                        ?.url
                    }
                    alt=""
                  />
                </div>
                <div className="content-container">
                  {/* <div className="social-media-container">
                    <p className="text-muted">Share</p>
                    <ul>
                      <li>
                        <div className="icon-container">
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                            target="_blank"
                          >
                            <svg
                              width="16"
                              height="30"
                              viewBox="0 0 16 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.43218 30V15.9231H0.709961V10.8548H4.43218V6.52575C4.43218 3.12396 6.63091 0 11.6973 0C13.7485 0 15.2654 0.19665 15.2654 0.19665L15.1458 4.92963C15.1458 4.92963 13.5989 4.91457 11.9109 4.91457C10.0839 4.91457 9.79114 5.75652 9.79114 7.15395V10.8548H15.2911L15.0518 15.9231H9.79114V30H4.43218Z"
                                fill="#032D42"
                              />
                            </svg>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div
                          className="icon-container"
                          style={{ color: "#032D42" }}
                        >
                          <a
                            href={`https://twitter.com/share?url=${window.location.href}`}
                            target="_blank"
                          >
                            <svg
                              width="30"
                              height="26"
                              viewBox="0 0 30 26"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.42375 25.1243C20.745 25.1243 26.9381 15.7437 26.9381 7.6231C26.9381 7.3606 26.9381 7.09435 26.9269 6.83185C28.1326 5.95901 29.1732 4.87838 30 3.6406C28.8737 4.13719 27.6802 4.46448 26.4581 4.61185C27.7454 3.84242 28.7095 2.63179 29.1712 1.20497C27.9619 1.92145 26.6382 2.42442 25.2581 2.69185C24.3303 1.70377 23.1026 1.04919 21.7652 0.829517C20.4277 0.609841 19.0551 0.837324 17.86 1.47673C16.6649 2.11613 15.714 3.13178 15.1546 4.36633C14.5952 5.60089 14.4585 6.98547 14.7656 8.3056C12.3183 8.18288 9.92403 7.5471 7.7382 6.43949C5.55238 5.33188 3.62378 3.77718 2.0775 1.87622C1.29251 3.232 1.05295 4.83572 1.40746 6.36172C1.76197 7.88772 2.68398 9.22159 3.98625 10.0925C3.01039 10.0593 2.05598 9.79727 1.2 9.32747V9.41185C1.20168 10.8321 1.6937 12.2083 2.59289 13.3076C3.49208 14.407 4.74328 15.1622 6.135 15.4456C5.60674 15.5911 5.06106 15.6637 4.51312 15.6612C4.12684 15.6624 3.74134 15.6266 3.36188 15.5543C3.75522 16.777 4.52114 17.8459 5.55234 18.6115C6.58354 19.3771 7.82838 19.8009 9.1125 19.8237C6.93104 21.5371 4.23638 22.4664 1.4625 22.4618C0.973726 22.4639 0.485293 22.4357 0 22.3775C2.81534 24.1723 6.08493 25.1254 9.42375 25.1243Z"
                                fill="#032D42"
                              />
                            </svg>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="icon-container">
                          <a
                            href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                            target="_blank"
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_162_2364)">
                                <path
                                  d="M25.5562 25.5666H21.1191V18.6038C21.1191 16.9434 21.0844 14.8097 18.7987 14.8097C16.485 14.8097 16.1325 16.6106 16.1325 18.4809V25.5656H11.6897V11.2491H15.9572V13.2019H16.0144C16.6106 12.0741 18.0609 10.8881 20.2247 10.8881C24.7256 10.8881 25.5609 13.8516 25.5609 17.7047V25.5656L25.5562 25.5666ZM6.67031 9.2925C6.3312 9.29262 5.99539 9.22584 5.68212 9.09599C5.36885 8.96613 5.08428 8.77574 4.8447 8.53573C4.60513 8.29572 4.41526 8.0108 4.28597 7.6973C4.15668 7.3838 4.09051 7.04787 4.09125 6.70875C4.09181 6.19833 4.2437 5.69953 4.52771 5.27543C4.81173 4.85133 5.21512 4.52096 5.68689 4.3261C6.15865 4.13124 6.6776 4.08064 7.17812 4.1807C7.67863 4.28075 8.13825 4.52697 8.49884 4.88822C8.85944 5.24947 9.10482 5.70953 9.20396 6.21023C9.30311 6.71093 9.25157 7.22978 9.05585 7.70119C8.86014 8.1726 8.52904 8.57539 8.10442 8.85864C7.6798 9.14189 7.18073 9.29287 6.67031 9.2925ZM8.89594 25.5666H4.44375V11.25H8.89594V25.5666ZM27.7838 0H2.2125C0.990937 0 0 0.966562 0 2.16281V27.8381C0 29.0344 0.990937 30.0009 2.2125 30.0009H27.7791C28.9988 30.0009 30 29.0344 30 27.8381V2.16281C30 0.966562 28.9988 0 27.7791 0H27.7838Z"
                                  fill="#032D42"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_162_2364">
                                  <rect width="30" height="30" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="icon-container">
                          <a
                            href={`mailto:?subject=I wanted you to see this site&amp;body=Check out this site ${window.location.href}`}
                            target="_blank"
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M27 11.0595V21.75C27 22.7446 26.6049 23.6984 25.9016 24.4016C25.1984 25.1049 24.2446 25.5 23.25 25.5H6.75C5.75544 25.5 4.80161 25.1049 4.09835 24.4016C3.39509 23.6984 3 22.7446 3 21.75V11.0595L14.619 17.8965C14.7344 17.9646 14.866 18.0005 15 18.0005C15.134 18.0005 15.2656 17.9646 15.381 17.8965L27 11.0595ZM23.25 6C24.1727 5.99985 25.0631 6.33991 25.7508 6.95511C26.4385 7.57031 26.8753 8.41746 26.9775 9.3345L15 16.38L3.0225 9.3345C3.12474 8.41746 3.56149 7.57031 4.24919 6.95511C4.9369 6.33991 5.82728 5.99985 6.75 6H23.25Z"
                                fill="#032D42"
                              />
                            </svg>
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className="icon-container">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.9304 13.0711C15.9065 12.0476 14.5181 11.4727 13.0704 11.4727C11.6227 11.4727 10.2343 12.0476 9.21037 13.0711L5.34912 16.9311C4.32522 17.955 3.75 19.3437 3.75 20.7917C3.75 22.2397 4.32522 23.6284 5.34912 24.6523C6.37302 25.6762 7.76173 26.2514 9.20975 26.2514C10.6578 26.2514 12.0465 25.6762 13.0704 24.6523L15.0004 22.7223"
                              stroke="#032D42"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M13.0703 16.9304C14.0942 17.9538 15.4826 18.5288 16.9303 18.5288C18.378 18.5288 19.7664 17.9538 20.7903 16.9304L24.6516 13.0704C25.6755 12.0465 26.2507 10.6578 26.2507 9.20975C26.2507 7.76173 25.6755 6.37302 24.6516 5.34912C23.6277 4.32522 22.239 3.75 20.7909 3.75C19.3429 3.75 17.9542 4.32522 16.9303 5.34912L15.0003 7.27912"
                              stroke="#032D42"
                              stroke-width="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </li>

                    </ul>
                  </div> */}
                  <div className="text-container" style={{ textAlign: "justify" }}>
                    <p className="">
                      {/* <ReactMarkdown>
                        {blogPost[0]?.attributes?.SubDescription}
                      </ReactMarkdown> */}
                      <ReactMarkdown
                        children={blogPost[0]?.attributes?.SubDescription}
                        components={{
                          a: ({ node, ...props }) => (
                            <u
                              target="_blank"
                              href={props}
                              style={{
                                textDecoration: "underline",
                              }}
                              {...props}
                            />
                          ),
                        }}
                        remarkPlugins={[remarkGfm]}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ marginTop: "6rem", marginBottom: "-2rem" }} />
          </div>
        </section>

        <SuggestedPosts data={suggestedPosts} />
        <CTA2 data={cta2} />
      </main>
    </Page>
  )
}

export default BlogDetail

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
