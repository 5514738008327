import React, { useContext, useEffect, useState } from "react"
import StateContext from "../../../StateContext"
import Hero from "../FAQ/Hero/Hero"
import SecAccordion from "./Accordion/SecAccordion"
import CTA2 from "../../CTA2/CTA2"
import Loader from "../../Loader/Loader"
import Page from "../Page"
function FAQComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [hero, setHero] = useState()
  const [secAccordion, setSecAccordion] = useState()
  const [cta2, setCta2] = useState()
  useEffect(() => {
    if (appState?.faqPage?.data) {
      appState?.faqPage?.data?.map((item, i) => {
        switch (item.id) {
          case 26:
            setHero(item)
            return
          case 27:
            setSecAccordion(item)
            return
          case 5:
            setCta2(item)
            return
          default:
            return
        }
      })
      setLoading(false)
    }
  }, [appState])

  if (loading) {
    return <Loader />
  }
  return (
    <main className="page-faq">
      <Page title="FAQ">
        <Hero data={hero} />
        <SecAccordion data={secAccordion} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default FAQComponent
