import React, { useState, useEffect } from "react";
import logo from "../../Assets/images/Logo.png";
import logoDark from "../../Assets/images/footer-logo.svg";
import { Link, useLocation } from "react-router-dom";
function Nav() {
  const [showResponsiveLogo, setShowResponsiveLogo] = useState(false);
  let location = useLocation();
  const [sticky, setIsSticky] = useState(false);
  const [showNav, setShowNav] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    setShowNav(false);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    if (
      location.pathname.includes("/career/") ||
      location.pathname.includes("/blogs/") ||
      location.pathname == "/demo" ||
      location.pathname == "/tier" ||
      location.pathname == "/company/ourTeam" ||
      location.pathname == "/blogs" ||
      location.pathname == "/products" ||
      location.pathname == "/privacy-policy" ||
      location.pathname == "/terms"
    ) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  }, [location.pathname]);

  const onResize = () => {
    if (window.innerHeight < 991) {
      setShowResponsiveLogo(true);
    }
  };

  const isSticky = (e) => {
    const header = document.querySelector("header");
    const scrollTop = window.scrollY;

    if (
      !location.pathname.includes("/career/") &&
      !location.pathname.includes("/blogs/") &&
      location.pathname !== "/demo" &&
      location.pathname !== "/tier" &&
      location.pathname !== "/company/ourTeam" &&
      location.pathname !== "/blogs" &&
      location.pathname !== "/products" &&
      location.pathname !== "/privacy-policy" &&
      location.pathname !== "/terms"
    ) {
      scrollTop >= window.innerHeight ? setIsSticky(true) : setIsSticky(false);
    }
  };

  //MAKE BODY UNSCROLLABLE WHEN NAV OPENS
  useEffect(() => {
    if (showNav) {
      document.querySelector("body").classList.add("menu-opened");
    } else {
      document.querySelector("body").classList.remove("menu-opened");
    }
  }, [showNav]);
  return (
    <>
      <div
        className={
          sticky && showNav
            ? " mobile-header is-sticky show-nav "
            : showNav
            ? "mobile-header is-sticky show-nav"
            : sticky
            ? "mobile-header is-sticky"
            : "mobile-header"
        }
      >
        <div className="container">
          <div className="row justify-content-end">
            <div className="col">
              <Link to="/">
                <div className="image-container logo-normal">
                  <img src={logo} alt="" />
                </div>
              </Link>
              <Link to="/">
                <div className="image-container logo-dark">
                  <img src={logoDark} alt="" />
                </div>
              </Link>
            </div>
            <div className="col">
              <div className="hamburger">
                <label for="check">
                  <input
                    type="checkbox"
                    onChange={() => setShowNav(!showNav)}
                    id="check"
                  />
                  <span></span>
                  <span></span>
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header
        className={
          sticky && showNav
            ? "is-sticky show-nav"
            : showNav
            ? " is-sticky show-nav"
            : sticky
            ? "is-sticky"
            : ""
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-8">
              <div className="nav-left">
                <div className="logo-box">
                  <Link to="/">
                    <div className="image-container logo-normal">
                      <img src={logo} alt="" />
                    </div>
                  </Link>
                  <Link to="/">
                    <div className="image-container logo-dark">
                      <img src={logoDark} alt="" />
                    </div>
                  </Link>
                </div>
                <div className="nav-container">
                  <ul className="nav-list">
                    <li
                      className={`nav-item ${
                        location.pathname.includes("product") ? "active" : ""
                      }`}
                    >
                      <Link to="/products">Product</Link>
                    </li>
                    <li
                      className={`nav-item ${
                        location.pathname.includes("company") ? "active" : ""
                      }`}
                    >
                      <Link to="/company">Company</Link>
                    </li>
                    <li
                      className={`nav-item ${
                        location.pathname.includes("blogs") ? "active" : ""
                      }`}
                    >
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    {/* <li
                      className={`nav-item ${
                        location.pathname.includes("tier") ? "active" : ""
                      }`}
                    >
                      <Link to="/tier">Tier</Link>
                    </li> */}
                    <li
                      className={`nav-item ${
                        location.pathname.includes("career") ? "active" : ""
                      }`}
                    >
                      <Link to="/career">Career</Link>
                    </li>
                    <li
                      className={`nav-item ${
                        location.pathname.includes("contact") ? "active" : ""
                      }`}
                    >
                      <Link to="/contact">Contact us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-4">
              {/* <div className="hamburger">
                <label for="check">
                  <input
                    type="checkbox"
                    onChange={() => setShowNav(!showNav)}
                    id="check"
                    checked
                  />
                  <span></span>
                  <span></span>
                  <span></span>
                </label>
              </div> */}
              <div className="nav-right">
                <div className="button-group">
                  <div className="button-container">
                    <span className="overlay"></span>
                    <a
                      href="https://dnh-prod.netlify.app/#/auth/login"
                      className="btn btn-transparent"
                    >
                      Login
                    </a>
                  </div>
                  <div className="button-container">
                    <span className="overlay"></span>
                    <Link to="/demo" className="btn btn-filled">
                      Request a Demo
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Nav;
