import React, { useContext, useEffect, useState } from "react"
import StateContext from "../../../StateContext"
//COMPONENTS
import Hero from "./Hero/Hero"
import WhoWeAre from "./WhoWeAre/WhoWeAre"
import OurExpertise from "./OurExpertise/OurExpertise"
import GetToKnowUs from "./GetToKnowUs/GetToKnowUs"
import CTA2 from "../../CTA2/CTA2"
import Loader from "../../Loader/Loader"
import Page from "../Page"
function CompanyComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [heroData, setHeroData] = useState()
  const [whoWeAre, setWhoWeAre] = useState()
  const [ourExpertise, setOurExpertise] = useState()
  const [getToKnowUs, setGetToKnowUs] = useState()
  const [cta2, setCta2] = useState()

  useEffect(() => {
    if (appState?.companyPage?.data) {
      appState?.companyPage?.data?.map((item, i) => {
        switch (item.id) {
          case 7:
            setHeroData(item)
            return
          case 8:
            setWhoWeAre(item)
            return
          case 10:
            setOurExpertise(item)
            return
          case 11:
            setGetToKnowUs(item)
            return
          case 5:
            setCta2(item)
            return
          default:
            return
        }
      })
      setLoading(false)
    }
  }, [appState])

  if (loading) {
    return <Loader />
  }
  return (
    <main className="company-page">
      <Page title="Company">
        <Hero data={heroData} />
        <WhoWeAre data={whoWeAre} />
        <OurExpertise data={ourExpertise} />
        <GetToKnowUs data={getToKnowUs} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default CompanyComponent
