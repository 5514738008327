import React from "react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import image1 from "../../../../Assets/images/location.jpg"
import image2 from "../../../../Assets/images/team.jpg"
function Locations(props) {
  const [locationList, setLocationList] = useState(null)

  useEffect(() => {
    setLocationList(
      props.data?.attributes?.section_contents?.data[0]?.attributes?.SubDescription.split(
        "\n"
      )
    )
  }, [props])
  return (
    <section className="section-locations">
      <div className="container">
        {props.data?.attributes?.section_contents?.data?.map((item, i) => {
          if (i % 2 == 0) {
            return (
              <div className="row align-items-center" key={i}>
                <div className="col-md-4">
                  <div class="content-container">
                    <div class="header-box">
                      <h1>{item?.attributes?.Title}</h1>
                    </div>
                    <div class="text-box">
                      <p class="subtitle">{item?.attributes?.SubTitle}</p>
                      <ul>
                        {locationList?.map((item, i) => {
                          return (
                            <li key={i} className="subtitle">
                              {item.substr(2, item.length - 1)}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 offset-md-1">
                  <div className="image-container">
                    <img
                      src={
                        item?.attributes?.SectionImage?.data &&
                        item?.attributes?.SectionImage?.data?.attributes?.url
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )
          } else {
            return (
              <div className="row align-items-center" key={i}>
                <div className="col-md-7 ">
                  <div className="image-container">
                    <img
                      src={
                        item?.attributes?.SectionImage?.data &&
                        item?.attributes?.SectionImage?.data?.attributes?.url
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-4 offset-md-1">
                  <div class="content-container">
                    <div class="header-box">
                      <h1>{item?.attributes?.Title}</h1>
                    </div>
                    <div class="text-box">
                      <p class="subtitle">{item?.attributes?.Description}</p>
                    </div>
                    <div className="button-container">
                      <span className="overlay"></span>
                      <Link
                        to="/company/ourTeam"
                        className="btn btn-transparent"
                      >
                        Meet Our Team
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
    </section>
  )
}

export default Locations
