import React from "react"
import { Modal } from "react-bootstrap"
import deleteIcon from "../../Assets/images/ant-design_delete-filled.png"
import successIcon from "../../Assets/images/job-success.png"

function SuccessMessage({ show, setShow, error, messageMain, messageSub }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-small"
      aria-labelledby="status-message"
      className="status-message"
    >
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <div className="image-container">
          <img src={successIcon} alt="" />
        </div>
        <h4 className="jobappliesuccessheader1">{messageMain}</h4>
        <p className="text-muted textmutesize1">{messageSub}</p>
        <div class="button-container" style={{ width: "15rem" }}>
          <span class="overlay"></span>
          <button class="btn btn-primary w-100" onClick={() => setShow(false)}>
            Done
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SuccessMessage
