import React, { useState, useContext } from "react"
import { useEffect } from "react"
import { Modal } from "react-bootstrap"
import DispatchContext from "../../DispatchContext"
import CookiePolicy from "./CookiePolicy"
import StateContext from "../../StateContext"
import axios from "axios"
import Loader from "../Loader/Loader"
import CookiesJS from 'js-cookie'
import { Link } from "react-router-dom"
import ReactMarkdown from 'react-markdown'

function CookiePopup({ showCookiePolicy, setShowCookiePolicy }) {
  const appDispatch = useContext(DispatchContext)
  const appState = useContext(StateContext)
  const [show, setShow] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [maniCookie, setManiCookie] = useState()
  // const [showCookiePolicy, setShowCookiePolicy] = useState()

  //FETCH HOMEPAGE DATA
  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    async function fetch() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}[$contains]=CookiePolicy`,
          { cancelToken: ourRequest.token }
        )

        if (response.data) {
          appDispatch({
            type: "FETCH_COOKIEPOLICY_SUCCESS",
            value: response.data,
          })
        }
      } catch (e) {
        appDispatch({ type: "FETCH_COOKIEPOLICY_FAILURE" })
      }
    }
    fetch()
    return () => ourRequest.cancel()
  }, [])

  useEffect(() => {
    appDispatch({ type: "SHOW_COOKIE_MODAL", value: false })
    // setManiCookie(appState.cookiePolicy?.data[0]?.attributes?.section_contents?.data?.filter((item) => item?.attributes?.SubTitle === "1"))
  }, [])

  function handlePopups() {
    // appDispatch({ type: "SHOW_COOKIE_MODAL", value: false })
    // setShowCookiePolicy(true)
    setShowModal(true)
  }


  function handleAcceptCookie() {
    // appDispatch({ type: "SHOW_COOKIE_MODAL", value: false })
    // appDispatch({ type: "SET_COOKIE_ACCEPTED" })

    CookiesJS.remove("COMMUNICATION_MARKETING")
    CookiesJS.set('ALLACCEPTEDCOOKIES', "true")
    setShowCookiePolicy(false)
  }

  const handleDenyCookie = () => {
    CookiesJS.remove("COMMUNICATION_MARKETING")
    CookiesJS.set('ALLACCEPTEDCOOKIES', "false")
    setShowCookiePolicy(false)

  }
  // if (!appState.cookiePolicy?.data)
  //   return <Loader />
  // }  
  return (
    <>
      <Modal
        // show={appState?.showCookieModal}
        show={showCookiePolicy}
        onHide={() => setShowCookiePolicy(false)}
        // onHide={() => appDispatch({ type: "SHOW_COOKIE_MODAL", value: false })}
        dialogClassName="modal-bottom cookie-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        centered={window.innerWidth < 700 ? true : false}
      >
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="content-container">
                  <p className="subtitle">
                    <ReactMarkdown
                      components={{
                        a: ({ node, ...props }) => <a href="/privacy-policy" style={{ fontSize: '16px', color: '#808285', textDecoration: 'underline' }} {...props} />
                      }}
                    >
                      {appState.cookiePolicy?.data[0]?.attributes?.section_contents?.data?.[2].attributes?.SubDescription}
                    </ReactMarkdown>
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <div className="button-group">
                  <div className="button-container">
                    <p
                      className="text-underline"
                      onClick={() => handlePopups()}
                    >
                      Preferences
                    </p>
                  </div>
                  <div class="button-container">
                    <span class="overlay"></span>
                    <button
                      class="btn btn-transparent  btn-filled "
                      onClick={() => handleAcceptCookie()}
                    >
                      Accept All cookies
                    </button>
                  </div>
                  <div class="button-container">
                    <span class="overlay"></span>
                    <button
                      class="btn btn-transparent btn-dark"
                      onClick={() => handleDenyCookie()}
                    >
                      Deny
                    </button>
                  </div>
                  {/* <div
                    className="icon-container"
                    onClick={() =>
                      appDispatch({ type: "SHOW_COOKIE_MODAL", value: false })
                    }
                  >
                    &times;
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <CookiePolicy
        showModal={showModal}
        setShowModal={(value) => setShowModal(value)}
      />
    </>
  )
}

export default CookiePopup
