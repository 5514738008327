import React, { useContext } from "react"
import { Link } from "react-router-dom"
import StateContext from "../../../../StateContext"
function SuggestedPosts(props) {
  return (
    <section className="section-related-articles">
      <div className="container">
        <div className="header-box text-center">
          <h2>Related Articles</h2>
        </div>
      </div>

      <div className="container card-container">
        <div className="row">
          {props.data?.map((item, i) => {
            if (i < 3 && item.id != 76) {
              const date = new Date(item.attributes.updatedAt)
              return (
                <div className="col-md-4">
                  <Link to={`/blogs/${item.id}`}>
                    <div className="content-container">
                      <div className="image-container">
                        <img
                          src={
                            item?.attributes?.SectionImage?.data &&
                            item?.attributes?.SectionImage?.data?.attributes
                              ?.url
                          }
                          alt=""
                        />
                      </div>
                      <div className="text-box">
                        <p className="subtitle">Subtitle Category</p>
                        <h4>{item?.attributes?.Title} </h4>
                        <p className="date">
                          {" "}
                          {monthNames[date.getMonth()]} {date.getDate()},{" "}
                          {date.getFullYear()}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            }
          })}
        </div>
      </div>
    </section>
  )
}

export default SuggestedPosts

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
