import React, { useContext, useEffect, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import DispatchContext from "../../../../DispatchContext"
import StateContext from "../../../../StateContext"
import axios from "axios"
import { useState } from "react"
import { useLocation } from "react-router-dom"
function Team(props) {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)
  const [numOfItemsToShow, setNumOfItemsToShow] = useState(5)
  const [currentJobList, setCurrentJobList] = useState([])
  const scrollhere = useRef(null)
  const location = useLocation()
  const history = useHistory()
  const [scrollIntoMe, setScrollIntoMe] = useState(location.hash === "#team" ? true : false)
  //fetch team data from the seperate api
  //FETCH PAGE DATA  

  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    async function fetch() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/job-posts`,
          { cancelToken: ourRequest.token }
        )

        if (response.data) {
          appDispatch({
            type: "FETCH_JOB-LISTS_SUCCESS",
            value: response.data,
          })
        }
      } catch (e) {
        appDispatch({ type: "FETCH_JOB_LISTS_FAILURE" })
      }
    }
    fetch()
    return () => ourRequest.cancel()
  }, [])

  //set pagination

  useEffect(() => {
    const newArray = []

    appState.jobList?.data?.map((item, i) => {
      if (i < numOfItemsToShow) {
        newArray.push(item)
      }
    })
    setCurrentJobList(newArray)
  }, [numOfItemsToShow, appState])

  useEffect(() => {
    scrollIntoMe && (
      setTimeout(() => {
        scrollhere.current.scrollIntoView()
        // console.log("scrollhere from settime out")
      }, 100)
    )
  }, [])

  const scrollhere1 = () => {
    // console.log(history.location.state.from, "history from teeam")
    scrollhere.current.scrollIntoView()
  }



  return (
    <section className="section-team" ref={scrollhere} id="team">
      <div className="container">
        <div className="header-box">
          <h1>Join Our Team!</h1>
        </div>
        <table className="">
          <tr>
            <th>Job Title</th>
            <th>Position</th>
            <th>Location</th>
          </tr>
          {currentJobList?.sort((a, b) => a.attributes?.Ordering - b.attributes?.Ordering).map((item, i) => {
            return (
              <tr>
                <td>
                  <Link to={`/career/${item.id}`}>
                    {item.attributes?.Title}
                  </Link>
                </td>
                <td>{item.attributes?.SubTeam}</td>
                <td>{item.attributes?.Location}</td>
              </tr>
            )
          })}
        </table>
        <div
          className="button-container"
          onClick={() => setNumOfItemsToShow(numOfItemsToShow + 5)}
        >
          <span className="overlay"></span>
          <button className="btn btn-primary">See All Opportunities</button>
        </div>
      </div>
    </section>
  )
}

export default Team
