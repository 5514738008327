import React from "react"
import image from "../../../../Assets/images/hero-small.jpg"
function Hero(props) {
  return (
    <section
      className="section-hero hero-small"
      style={{
        backgroundImage: `url(${
          props.data?.attributes?.Image?.data &&
          props.data?.attributes?.Image?.data[0]?.attributes?.url
            ? props.data?.attributes?.Image?.data &&
              props.data?.attributes?.Image?.data[0]?.attributes?.url
            : image
        })`,
      }}
    >
      {/* <div className="hero-conatiner">
        <div className="image-container">
          <img src={image} alt="" />
        </div>
      </div> */}
      <div className="container">
        <div className="text-box">
          <h1> {props.data?.attributes?.Title}</h1>
        </div>
      </div>
    </section>
  )
}

export default Hero
