import React, { useContext, useEffect, useState } from "react"
import StateContext from "../../../StateContext"
import SectionForm from "./SectionForm/SectionForm"
import CTA2 from "../../CTA2/CTA2"
import Loader from "../../Loader/Loader"
import Page from "../Page"
function DemoComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [sectionForm, setSectionForm] = useState()
  const [cta2, setCta2] = useState()
  useEffect(() => {
    if (appState?.demoPage?.data) {
      // appState.demoPage.data)
      appState?.demoPage?.data?.map((item, i) => {
        switch (item.id) {
          case 25:
            setSectionForm(item)
            return
          case 5:
            setCta2(item)
            return
          default:
            return
        }
      })
      setLoading(false)
    }
  }, [appState])

  if (loading) {
    return <Loader />
  }
  return (
    <main className="page-demo">
      <Page title="Demo">
        <SectionForm data={sectionForm} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default DemoComponent
