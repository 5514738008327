import React from "react";
import { Link } from "react-router-dom";
import image from "../../Assets/images/cta2.jpg";
function CTA2(props) {
  return (
    <section
      className="section-cta2"
      style={{
        backgroundImage: `url(${
          props?.data?.attributes?.Image &&
          props?.data?.attributes?.Image?.data[0]?.attributes?.url
            ? props?.data?.attributes?.Image &&
              props?.data?.attributes?.Image?.data[0]?.attributes?.url
            : image
        })`,
      }}
    >
      <div className="container">
        <div className="content-container">
          <div className="header-box">
            <h1>
              {props.data
                ? `${props?.data?.attributes?.Title}`
                : "Try for free!"}
              <div style={{ width: "200px", margin: "0 20px" }}>
                <img
                  src={props?.data?.attributes?.Image?.data[1]?.attributes?.url}
                  alt=""
                />
              </div>
              {props?.data?.attributes?.Url}
            </h1>
          </div>
          <div className="text-box">
            <p className="subtitle">
              {props.data
                ? ` ${props?.data?.attributes?.Description}`
                : "Sit amet consectetur adipiscing elit sed do eiusmod tem porincididunt ut labore et dolore magna aliqua. Quis psum suspendisse ultrices gravida com...."}
            </p>
          </div>
          <div className="button-container">
            <span className="overlay"></span>
            <a
              href="https://dnh-prod.netlify.app/#/auth/sign-up"
              className="btn btn-transparent"
            >
              Try For Free
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CTA2;
