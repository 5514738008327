import React, { useState } from "react"
import { useEffect, useContext } from "react"
import { Modal } from "react-bootstrap"
import Page from "../Pages/Page"
import StateContext from "../../StateContext"
import DispatchContext from "../../DispatchContext"
import Loader from "../Loader/Loader"
import CookiesJS from 'js-cookie'

// const CookiePolicy = (props) => {
//   const [acceptedAll, setAcceptedAll] = useState(localStorage.getItem('ALLACCEPTEDCOOKIES'));

//   return (
//     <CookieModal acceptedAll={acceptedAll} {...props} />
//   )
// }

function CookiePolicy(props) {
  const { showModal, setShowModal } = props;
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)
  const [data, setData] = useState(null)
  const [acceptedAll, setAcceptedAll] = useState(CookiesJS.get("ALLACCEPTEDCOOKIES"));
  const [firstCookie, setFirstCookie] = useState(acceptedAll === "true" ? "ON" : CookiesJS.get("COMMUNICATION_MARKETING") === "ON" ? "ON" : CookiesJS.get("ALLACCEPTEDCOOKIES") === "false" ? "OFF" : null)


  function handleCookieOne(value) {
    appDispatch({
      type: "SET_COOKIE_SETTING",
      value: value,
      cateogry: "measureWebisteUse",
    })
  }
  function handleCookieTwo(value) {
    appDispatch({
      type: "SET_COOKIE_SETTING",
      value: value,
      cateogry: "helpMarketing",
    })
  }
  function handleSetCookie(val) {
    setShowModal(false)
    appDispatch({
      type: "SET_COOKIE_ACCEPTED",
    })
  }

  const handleInputChange = (value) => {
    setFirstCookie(value)
  }

  function handleCookiePreference(e) {
    e.preventDefault();
    if (firstCookie === "ON") {
      CookiesJS.set("COMMUNICATION_MARKETING", "ON")
      CookiesJS.set('ALLACCEPTEDCOOKIES', "true")
    }
    else if (firstCookie === "OFF") {
      CookiesJS.set("COMMUNICATION_MARKETING", "OFF")
      CookiesJS.set('ALLACCEPTEDCOOKIES', "false")
    }

    setShowModal(false)
    // appDispatch({
    //   type: "SET_COOKIE_PREFERENCE",
    // })
  }

  function rejectCookiePreference() {
    setShowModal(false)
    CookiesJS.remove("COMMUNICATION_MARKETING")
    CookiesJS.set('ALLACCEPTEDCOOKIES', "false")
    // appDispatch({
    //   type: "REJECT_COOKIE_PREFERENCE",
    // })
  }


  useEffect(() => {
    setFirstCookie(CookiesJS.get("ALLACCEPTEDCOOKIES") === "true" ? "ON" : CookiesJS.get("ALLACCEPTEDCOOKIES") === "false" ? "OFF" : null)
  }, [CookiesJS.get("ALLACCEPTEDCOOKIES")])

  
  useEffect(() => {
    if (appState?.cookiePolicy?.data[0]) {
      setData(appState?.cookiePolicy?.data[0].attributes?.section_contents)
    }
  }, [appState])


  return (
    <Page title="">
      <Modal
        size="xl"
        show={showModal}
        onHide={() => setShowModal(false) && setFirstCookie()}
        dialogClassName="modal-70w"
        aria-labelledby="cookie-policy"
        centered
      >
        <Modal.Header>
          <Modal.Title id="cookie-policy">
            <h2 className="modal70wh1">{data?.data[0]?.attributes?.Title}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <ul className="modal-content-list">
              {/* {appState?.cookiePolicy?.data[0].attributes?.section_contents.map(
              (item, i) => {
                return <h1>aklsdjfld</h1>
              }
            )} */}
              {data?.data?.map((item, i) => {
                if (i == 0) {
                  return (
                    <li className="modal-content-item" key={i}>
                      <div className="content-container">
                        <div className="header-box">
                          <h3>{item?.attributes?.Title}</h3>
                        </div>
                        <div className="text-box">
                          <p className="subtitle2">
                            {item?.attributes?.Description}
                          </p>
                        </div>
                        <div className="controls">
                          <p className="label-dark">
                            <i>Always On</i>
                          </p>
                        </div>
                      </div>
                    </li>
                  )
                }
                if (i == 1) {
                  return (
                    <li className="modal-content-item" key={i}>
                      <div className="content-container">
                        <div className="header-box">
                          <h3>{item?.attributes?.Title}</h3>
                        </div>
                        <div className="text-box">
                          <p className="subtitle2">
                            {item?.attributes?.Description}
                          </p>
                        </div>
                        <div className="controls">
                          <div
                            className="input-group"
                            onChange={(e) => handleCookieOne(e.target.value)}
                          >
                            <label>
                              On
                              <input
                                type="radio"
                                name="group1"
                                className=" custom-control-input form-contro"
                                value="ON"
                                defaultChecked={firstCookie === "ON" ? true : false}
                                onChange={(e) => handleInputChange(e.target.value)}

                              />
                            </label>
                            <label>
                              Off
                              <input
                                type="radio"
                                name="group1"
                                className=" custom-control-input form-contro"
                                value="OFF"
                                defaultChecked={firstCookie === "OFF" ? true : false}
                                onChange={(e) => handleInputChange(e.target.value)}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                }
              })}

              {/* <li className="modal-content-item">
              <div className="content-container">
                <div className="header-box">
                  <h3>Cookies that are strictly necessary</h3>
                </div>
                <div className="text-box">
                  <p className="subtitle">
                    These cookies are necessary for the website to function and
                    cannot be switched off in our systems. They are usually only
                    set in response to actions made by you which amount to a
                    request for services, such as setting your privacy
                    preferences, logging in or filling in forms. You can set
                    your browser to block or alert you about these cookies, but
                    some parts of the site will not then work. These cookies do
                    not store any personally identifiable information.
                  </p>
                </div>
                <div className="controls">
                  <p className="label-dark">
                    <i>Always On</i>
                  </p>
                </div>
              </div>
            </li>
            <li className="modal-content-item">
              <div className="content-container">
                <div className="header-box">
                  <h3>Cookies that measure website use</h3>
                </div>
                <div className="text-box">
                  <p className="subtitle">
                    These cookies are necessary for the website to function and
                    cannot be switched off in our systems. They are usually only
                    set in response to actions made by you which amount to a
                    request for services, such as setting your privacy
                    preferences, logging in or filling in forms. You can set
                    your browser to block or alert you about these cookies, but
                    some parts of the site will not then work. These cookies do
                    not store any personally identifiable information.
                  </p>
                </div>
                <div className="controls">
                  <div
                    className="input-group"
                    onChange={(e) => handleCookieOne(e.target.value)}
                  >
                    <label>
                      On
                      <input
                        type="radio"
                        name="group1"
                        className=" custom-control-input form-contro"
                        value="On"
                      />
                    </label>
                    <label>
                      Off
                      <input
                        type="radio"
                        name="group1"
                        className=" custom-control-input form-contro"
                        value="Off"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </li>
            <li className="modal-content-item">
              <div className="content-container">
                <div className="header-box">
                  <h3>
                    Cookies that help with our communications and marketing
                  </h3>
                </div>
                <div className="text-box">
                  <p className="subtitle">
                    These cookies are necessary for the website to function and
                    cannot be switched off in our systems. They are usually only
                    set in response to actions made by you which amount to a
                    request for services, such as setting your privacy
                    preferences, logging in or filling in forms. You can set
                    your browser to block or alert you about these cookies, but
                    some parts of the site will not then work. These cookies do
                    not store any personally identifiable information.
                  </p>
                </div>
                <div className="controls">
                  <div
                    className="input-group"
                    onChange={(e) => handleCookieTwo(e.target.value)}
                  >
                    <label>
                      On
                      <input
                        type="radio"
                        name="group2"
                        className=" custom-control-input form-contro"
                        value="On"
                      />
                    </label>
                    <label>
                      Off
                      <input
                        type="radio"
                        name="group2"
                        className=" custom-control-input form-contro"
                        value="Off"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </li> */}
            </ul>
            <div className="modal-controls">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="button-group d-flex cookiepopoup">
                      <div class="button-container mr-3 btn21">
                        <span class="overlay"></span>
                        <a class="btn btn-transparent btn-dark"
                          onClick={(e) => handleCookiePreference(e)}>
                          Save Preferences
                        </a>
                      </div>
                      <div class="button-container">
                        <span class="overlay"></span>
                        <a
                          class="btn btn-transparent btn-dark"
                          onClick={() => rejectCookiePreference(true)}
                        >
                          Reject non-sessential cookies
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-4">
                  <div className="button-group d-flex justify-content-end">
                    <div class="button-container">
                      <span class="overlay"></span>
                      <a
                        class="btn btn-filled btn-primary"
                        onClick={() => handleSetCookie(true)}
                      >
                        Accept all Cookies
                      </a>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Page>
  )
}

export default CookiePolicy

