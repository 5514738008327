import React from "react"
import image from "../../../../Assets/images/hero-small.jpg"
function Hero(props) {
  return (
    <section className="section-hero hero-small">
      <div className="hero-conatiner">
        <div className="image-container">
          <img
            src={
              props.data?.attributes?.Image?.data &&
              props.data?.attributes?.Image?.data[0]?.attributes?.url
                ? props.data?.attributes?.Image?.data &&
                  props.data?.attributes?.Image?.data[0]?.attributes?.url
                : image
            }
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="text-box">
              <h1>{props.data?.attributes?.Title}</h1>
              <p>{props.data?.attributes?.Description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
