import React from "react"
import image from "../../../../Assets/images/teamwork.jpg"
function Part(props) {
  return (
    <section className="section-be-a-part">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <div className="image-container">
              <img
                src={
                  props.data?.attributes?.Image?.data &&
                  props.data?.attributes?.Image?.data[0]?.attributes?.url
                }
                alt=""
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="content-container">
              <div className="header-box">
                <h1> {props.data?.attributes?.Title}</h1>
              </div>
              <div className="text-box">
                <p className="subtitle">
                  {props.data?.attributes?.Description}
                </p>
              </div>
              {/* <div class="button-container">
                <span class="overlay"></span>
                <button class="btn  btn-primary">Explore</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Part
