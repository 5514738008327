import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import herobg from "../../Assets/images/hero.jpg"
import TypeAnimation from "react-type-animation"
import StateContext from "../../StateContext"
import Typewriter from 'typewriter-effect';
function Hero(props) {
  const appState = useContext(StateContext)
  const [data, setData] = useState(null)
  var regex = /[.]/;
  // useEffect(() => {
  //   if (appState?.homepage) {
  //     appState?.homepage?.data?.map((item, i) => {
  //       if (item.id == 6) {
  //         setData(item)
  //       }
  //     })
  //       "TITLES",
  //       data?.attributes?.section_contents?.data[0]?.attributes?.Title
  //     )
  //   }
  // }, [appState?.homepage?.data])

  // if (!data) {
  //   return (
  //     <>
  //       <h1>loading....</h1>
  //     </>
  //   )
  // }
  return (
    <section
      className="section-hero"
      style={{
        backgroundImage: `url(${props.data?.attributes?.Image?.data[0]?.attributes?.url})`,
      }}
    >
      <div className="container">
        <div className="text-box">
          <h1>
            {props.data?.attributes?.Title}{" "}
            <span className="bold">
              {props.data?.attributes?.section_contents?.data?.length && (
                <Typewriter
                  options={{
                    strings: [
                      props.data?.attributes?.section_contents?.data[0]
                        ?.attributes?.Title.replace(regex, ""),
                      props.data?.attributes?.section_contents?.data[1]
                        ?.attributes?.Title.replace(regex, ""),
                      props.data?.attributes?.section_contents?.data[2]
                        ?.attributes?.Title.replace(regex, ""),
                      props.data?.attributes?.section_contents?.data[3]
                        ?.attributes?.Title.replace(regex, ""),
                    ],
                    autoStart: true,
                    delay: 140,
                    deleteSpeed: 100,
                    loop: true,
                    pauseFor: 1500,
                  }}
                />
              )}
            </span>
          </h1>
          <h1> {props.data?.attributes?.Description}</h1>
          <div className="button-container">
            <span className="overlay"></span>
            <Link to="/demo" className="btn">
              Get Started With Us
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
