import React from "react"
import image1 from "../../../../Assets/images/alternate1.png"
import image2 from "../../../../Assets/images/alternate2.png"
import image3 from "../../../../Assets/images/alternate3.png"
import image4 from "../../../../Assets/images/alternate4.png"
import image5 from "../../../../Assets/images/alternate5.png"
import image6 from "../../../../Assets/images/alternate6.png"
import image7 from "../../../../Assets/images/alternate7.png"
import image8 from "../../../../Assets/images/alternate9.png"
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm'
function Alternate(props) {

  return (
    <section className="section-alternate">
      {props.data?.attributes?.section_contents?.data?.map((item, i) => {
        if (i % 2 != 0) {
          return (
            <div className="alternate" key={i} id={`goto${i}`}>
              <div className="container">
                <div className="row">
                  <div className="col-md-5 ">
                    <div className="content-contain">
                      <div className="header-box">
                        <h1>{item?.attributes?.Title}</h1>
                      </div>
                      <div className="text-box">
                        <ReactMarkdown
                          className="subtitle"
                          rehypePlugins={[rehypeRaw]}
                          remarkPlugins={[remarkGfm]}
                        // components={{
                        //   a: ({ node, ...props }) => <u style={{ textDecoration: 'underline' }} {...props} />
                        // }}
                        >
                          {item?.attributes?.SubDescription}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 offset-md-1">
                    <div className="image-container">
                      <img
                        src={
                          item?.attributes?.SectionImage?.data &&
                          item?.attributes?.SectionImage?.data?.attributes?.url
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div className="alternate" key={i} id={`goto${i}`}>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="image-container">
                      <img
                        src={
                          item?.attributes?.SectionImage?.data &&
                          item?.attributes?.SectionImage?.data?.attributes?.url
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-5 offset-md-1">
                    <div className="content-contain">
                      <div className="header-box">
                        <h1>{item?.attributes?.Title}</h1>
                      </div>
                      <div className="text-box">
                        <ReactMarkdown className="subtitle">
                          {item?.attributes?.SubDescription}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })}

      <div className="container"></div>
    </section>
  )
}

export default Alternate
