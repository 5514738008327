import React from "react"
import { Link } from "react-router-dom"
import icon1 from "../../Assets/svg/request_a_demo.svg"
import icon2 from "../../Assets/svg/contact_sales.svg"
import icon3 from "../../Assets/svg/view_use_cases.svg"
function Cta(props) {
  return (
    <section className="section-cta">
      <div className="container">
        <div className="row setbox" style={{ rowGap: window.innerWidth < 700 ? "10px" : "" }}>
          {props?.data?.attributes?.section_contents?.data?.map((item, i) => {
            return (
              <div className="col-sm-4" key={i}>
                <Link to={item.attributes.Url}>
                  <div className="content-container">
                    <div className="icon-container">
                      <img
                        src={
                          item.attributes?.SectionImage?.data?.attributes?.url
                        }
                        alt=""
                      />
                    </div>
                    <div className="text-container">
                      <h2>{item.attributes.Title}</h2>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
          {/* <div className="col-sm-4">
            <Link to="/demo">
              <div className="content-container">
                <div className="icon-container">
                  <img
                    src={
                      props.data?.attributes?.section_contents?.data[0]
                        ?.attributes?.SectionImage?.data?.attributes?.url
                    }
                    alt=""
                  />
                </div>
                <div className="text-container">
                  <h2>Request a Demo</h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-4">
            <Link to="/contact">
              <div className="content-container">
                <div className="icon-container">
                  <img src={ props.data?.attributes?.section_contents?.data[2]
                        ?.attributes?.SectionImage?.data?.attributes?.url} alt="" />
                </div>
                <div className="text-container">
                  <h2>Contact Sales</h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-sm-4">
            <Link to="/products">
              <div className="content-container">
                <div className="icon-container">
                  <img src={icon3} alt="" />
                </div>
                <div className="text-container">
                  <h2>View Use Cases</h2>
                </div>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default Cta
