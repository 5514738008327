import React, { useContext, useEffect } from "react"
import CompanyComponent from "../Components/Pages/Company/CompanyComponent"
import axios from "axios"
import DispatchContext from "../DispatchContext"
function Company() {
  const appDispatch = useContext(DispatchContext)
  //FETCH PAGE DATA
  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    async function fetch() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}[$contains]=Company`,
          { cancelToken: ourRequest.token }
        )

        if (response.data) {
          appDispatch({
            type: "FETCH_COMPANY_PAGE_SUCCESS",
            value: response.data,
          })
        }
      } catch (e) {
        appDispatch({ type: "FETCH_COMPANY_PAGE_FAILURE" })
      }
    }
    fetch()
    return () => ourRequest.cancel()
  }, [])
  return <CompanyComponent />
}

export default Company
