import React, { useContext, useEffect, useState } from "react"
import StateContext from "../../../StateContext"
import Loader from "../../Loader/Loader"
import Page from "../Page"
import CTA from "./CTA/CTA"
import Hero from "./Hero/Hero"
import Locations from "./Locations/Locations"
import Part from "./Part/Part"
import SectionSlider from "./Slider/SectionSlider"
import Team from "./Team/Team"


function CareerComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [hero, setHero] = useState()
  const [part, setPart] = useState()
  const [slider, setSlider] = useState()
  const [location, setLocation] = useState()
  const [teams, setTeams] = useState()
  const [cta, setCta] = useState()

  useEffect(() => {
    if (appState?.careerPage?.data) {
      appState?.careerPage?.data?.map((item, i) => {
        switch (item.id) {
          case 17:
            setHero(item)
            return
          case 18:
            setPart(item)
            return
          case 19:
            setSlider(item)
            return
          case 20:
            setLocation(item)
            return
          case 21:
            setTeams(item)
            return
          case 22:
            setCta(item)
            return
          default:
            return
        }
      })
      setLoading(false)
    }
  }, [appState])
  if (loading) {
    return <Loader />

  }
  return (
    <main className="career-page">
      <Page title="Career">
        <Hero data={hero} />
        <Part data={part} />
        {/* <SectionSlider data={slider} /> */}
        <Locations data={location} />
        <Team data={teams} />
        <CTA data={cta} />
      </Page>
    </main>
  )
}

export default CareerComponent
