import React from "react"
import icon from "../../../../Assets/images/IcUser.png"
import icon2 from "../../../../Assets/images/IcIngredients.png"
import icon3 from "../../../../Assets/images/IcRecipe.png"
import icon4 from "../../../../Assets/images/IcProject.png"
import icon5 from "../../../../Assets/images/IcBioHazard.png"
import icon6 from "../../../../Assets/images/IcContact.png"
function GrowYourBusiness(props) {
  return (
    <section className="section-product-main">
      <div className="container">
        <div className="header-box text-center">
          <h1>{props.data?.attributes?.Title}</h1>
        </div>
      </div>
      <div className="product-category-container container equal-height ">
        <div className="row" style={{padding: window.innerWidth < 700 ? "0 2rem" : ""}}>
          {props.data?.attributes?.section_contents?.data?.map((item, i) => {
            return (
              <div className="col-lg-3 col-md-4 col-sm-6" key={i}>
                <a className="content-container card" href={`#goto${i}`}>
                  <div className="icon-container">
                    <img
                      src={
                        item?.attributes?.SectionImage?.data &&
                        item?.attributes?.SectionImage?.data?.attributes?.url
                      }
                      alt=""
                    />
                  </div>
                  <h5>{item?.attributes?.Title}</h5>

                  {/* <div class="button-container">
                    <span class="overlay"></span>
                    <a class="btn btn-transparent btn-dark" href={`#goto${i}`}>
                      Explore
                    </a>
                  </div> */}
                </a>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default GrowYourBusiness
