import React, { useContext, useEffect, useState } from "react"

import Loader from "../../Loader/Loader"
import StateContext from "../../../StateContext"
import BlogPosts from "./BlogPosts/BlogPosts"
import CTA2 from "../../CTA2/CTA2"
import SuggestedPosts from "./SuggestedPosts/SuggestedPosts"
import image1 from "../../../Assets/images/blog-main.jpg"
import image2 from "../../../Assets/images/blog2.jpg"
import image3 from "../../../Assets/images/blog3.jpg"
import image4 from "../../../Assets/images/blog4.jpg"
import image5 from "../../../Assets/images/blog5.jpg"
import image6 from "../../../Assets/images/blog6.jpg"
import image7 from "../../../Assets/images/blog7.jpg"
import image8 from "../../../Assets/images/blog8.jpg"
import image9 from "../../../Assets/images/blog9.jpg"
import image10 from "../../../Assets/images/blog10.jpg"
import image11 from "../../../Assets/images/blog11.jpg"
import image12 from "../../../Assets/images/blog12.jpg"
import Page from "../Page"

function BlogsComponents() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [blogPosts, setblogPosts] = useState()
  const [cta2, setCta2] = useState()

  useEffect(() => {
    if (appState?.blogPage?.data) {      
      appState?.blogPage?.data?.map((item, i) => {
        switch (item.id) {
          case 14:
            setblogPosts(item)
            return
          case 5:
            setCta2(item)
            return
          default:
            return
        }
      })
      setLoading(false)
    }
  }, [appState])

  if (loading) {
    return <Loader />
  }
  return (
    <main className="page-blogs">
      <Page title="Blogs">
        <BlogPosts data={blogPosts} />
      </Page>
    </main>
  )
}

export default BlogsComponents

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
