import React from "react"
import Page from "../Page"
function TermsComponent() {
  return (
    <Page title="Terms and Conditions">
      <main className="page-privacy-policy policy-page stikcy-header-yes">
        <div className="container">
          <p class="page-navigation">
            <span>D&H</span> <span>/ Terms and Conditions</span>
          </p>
        </div>
        <div className="container">
          <div className="policy-container">
            <div className="header-box text-center">
              <h1>Terms and Conditions</h1>
            </div>
            <div className="text-box">
              <p className="subtitle">Date of version: 4th August 2017</p>
              <p className="subtitle">Thank you for visiting this website.</p>
              <p className="subtitle">
                By using any Site, you indicate that you have read and accept
                these Terms and agree to abide by and be bound by all such
                Terms. If you do not accept all applicable Terms, please refrain
                from using any Site. Please note that we may change these Terms
                from time to time without notice so you should review them each
                time that you visit any Site.
              </p>
              <p className="subtitle">
                We draw your attention to the limitations on liability contained
                in clause 11 below and to the consents to data transfer and
                processing contained in clause 9 below.
              </p>
              <p className="subtitle">
                In addition to these Terms each Site may have supplementary
                terms and conditions which apply to the Site(s) on which they
                appear or the services available on the Site ("Additional
                Terms"). Please ensure that you read any Additional Terms and
                the Privacy Policy as they form part of these Terms in respect
                of the Site(s) to which they relate. To the extent that the
                Additional Terms conflict with these Terms, the Additional Terms
                shall prevail (including, without limitation, any liability
                limitations or exclusions in the Additional Terms), but only in
                respect of the Site(s) on which they appear.
              </p>
            </div>
          </div>
        </div>
      </main>
    </Page>
  )
}

export default TermsComponent
