import React from "react"
import image from "../../../../Assets/images/cta2.jpg"

import image1 from "../../../../Assets/images/cib_linkedin.png"
import { Link } from "react-router-dom"
function CTA(props) {

  const openLinkedIn = () => {
    window.open("https://www.linkedin.com/", "", "")
  }

  return (
    <section
      className="section-cta2"
      style={{
        backgroundImage: `url(${props.data?.attributes?.Image?.data[1]?.attributes?.url})`,
      }}
    >
      <div className="container">
        <div className="content-container">
          <div className="header-box">
            {/* <div className="image-container">
              <img
                src={props.data?.attributes?.Image?.data[0]?.attributes?.url}
                alt=""
              />
            </div> */}

            <h1>{props.data?.attributes?.Title}</h1>
          </div>
          <div className="text-box">
            <p className="subtitle">{props.data?.attributes?.Description}</p>
          </div>
          <Link to="/contact">
            <div className="button-container">
              <span className="overlay"></span>
              <span className="btn btn-transparent">
                Connect with Us
              </span>
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CTA
