import React, { useContext, useEffect, useState } from "react"
import StateContext from "../../../StateContext"

import CTA2 from "../../CTA2/CTA2"
import GrowYourBusiness from "./GrowYourBusiness/GrowYourBusiness"
import Alternate from "./Alternate/Alternate"
import Loader from "../../Loader/Loader"
import Page from "../Page"
function ProductComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [grow, setGrow] = useState()
  const [alternate, setAlternate] = useState()
  const [cta2, setCta2] = useState()
  useEffect(() => {
    if (appState?.productsPage?.data) {
      appState?.productsPage?.data?.map((item, i) => {
        switch (item.id) {
          case 12:
            setGrow(item)
            return
          case 13:
            setAlternate(item)
            return
          case 5:
            setCta2(item)
            return
          default:
            return
        }
      })
      setLoading(false)
    }
  }, [appState])

  if (loading) {
    return <Loader />
  }
  return (
    <main className="page-product">
      <Page title="Products">
        <GrowYourBusiness data={grow} />
        <Alternate data={alternate} />
        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default ProductComponent
