import React, { useState, useEffect } from "react"
import { useImmerReducer } from "use-immer"
import axios from "axios"
import SuccessMessage from "../../../SuccessMessage/SuccessMessage"
import DispatchContext from "../../../../DispatchContext"
import { useContext } from "react"
import CookiePopup from "../../../CookiePolicy/CookiePopup"
import CookiesJS from 'js-cookie'
import Spinner from 'react-bootstrap/Spinner';
import Formm from "react-bootstrap/Form"

function Form() {
  const appDispatch = useContext(DispatchContext)
  const [submitCount, setSubmitCount] = useState(0)
  const [isPending, setIsPending] = useState(false)
  const [isError, setIsError] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [spinner, setSpinner] = useState(false)

  const [show, setShoww] = useState(false)

  const initialState = {
    firstName: {
      label: "First Name",
      value: "",
      errorMessage: "",
    },
    lastName: {
      label: "Last Name",
      value: "",
      errorMessage: "",
    },
    companyName: {
      label: "Company Name",
      value: "",
      errorMessage: "",
    },
    department: {
      label: "Department",
      value: "",
      errorMessage: "",
    },
    email: {
      label: "Email",
      value: "",
      errorMessage: "",
    },
    date: {
      label: "Date",
      value: "",
      errorMessage: "",
    },
    message: {
      label: "Message",
      value: "",
      errorMessage: "",
    },
  }

  function ourReducer(draft, action) {
    switch (action.type) {
      case "firstNameChange":
        draft.firstName.errorMessage = ""
        if (/\d/.test(action.value)) {
          draft.firstName.errorMessage = "Name must not contain a number"
        }
        else if (/`[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]+/.test(action.value)) {
          draft.firstName.errorMessage = "Special charaters are not allowed"
        }
        draft.firstName.value = action.value
        return
      case "lastNameChange":
        draft.lastName.errorMessage = ""
        if (/\d/.test(action.value)) {
          draft.lastName.errorMessage = "Name must not contain a number"
        }
        else if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]+/.test(action.value)) {
          draft.lastName.errorMessage = "Special charaters are not allowed"
        }
        draft.lastName.value = action.value
        return
      case "companyChange":
        draft.companyName.errorMessage = ""
        draft.companyName.value = action.value
        return
      // case "departmentChange":
      //   draft.department.errorMessage = ""
      //   draft.department.value = action.value
      //   return
      case "departmentChange":
        draft.department.errorMessage = ""
        if (action.value === "Please select any") {
          draft.department.errorMessage = "This field is required"
        }
        draft.department.value = action.value
        return
      case "emailChange":
        draft.email.errorMessage = ""
        draft.email.value = action.value
        return
      case "validateEmail":
        if (!/^\S+@\S+$/.test(action.value)) {
          draft.email.errorMessage = "Invalid email format"
        }
        return
      case "dateChange":
        draft.date.errorMessage = ""

        draft.date.value = action.value
        return
      case "messageChange":
        draft.message.errorMessage = ""
        draft.message.value = action.value
        return
      case "vallidateFields":
        for (const key in draft) {
          if (!draft[key].value) {
            draft[key].errorMessage = "This field is required"
          }
        }
        let errorCount = 0
        for (const key in draft) {
          if (draft[key].errorMessage) {
            errorCount++
          }
        }

        //CHECK COOKIE
        // if (!localStorage.getItem("D&HmeasureWebsiteUseCookie")) {
        //   appDispatch({ type: "SHOW_COOKIE_MODAL", value: true })
        //   return
        // }
        if (!errorCount) {
          setSubmitCount(submitCount + 1)
        }
        return
      case "clearForm":
        for (const key in draft) {
          draft[key].value = ""
        }

        return
      default:
        return
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState)

  function handleSubmit(e) {
    e.preventDefault()
    if (CookiesJS.get("ALLACCEPTEDCOOKIES") === "true") {
      const data = new FormData(e.target)
      dispatch({ type: "vallidateFields" })
    }
    else {
      setShoww(true)
    }
  }

  useEffect(() => {
    if (submitCount) {
      setIsPending(true)
      setSpinner(true)
      const dataToSend = {
        FirstName: state.firstName.value,
        LastName: state.lastName.value,
        CompanyEmail: state.email.value,
        Message: state.message.value,
        Company: state.companyName.value,
        Date: state.date.value,
        Department: state.department.value,
        Message: state.message.value,
      }
      const ourRequest = axios.CancelToken.source()
      async function submit() {
        const config = {
          headers: {
            "content-type": "application/JSON",
          },
        }
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/request-demos`,
            {
              data: dataToSend,
            },
            config,
            { cancelToken: ourRequest.token }
          )

          if (response.data) {
            setIsPending(false)
            setSpinner(false)
            setIsSuccess(true)
            dispatch({ type: "clearForm" })
          }
        } catch (e) {
          setIsPending(false)
          setIsError("Something went wrong, please try again")
        }
      }
      submit()
      return () => ourRequest.cancel()
    }
  }, [submitCount])
  return (
    <>
      <form onSubmit={handleSubmit} novalidate>
        <div className="header-box">
          <h2>Request a demo</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div
                className={`form-item ${state.firstName.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>First Name</p>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({
                        type: "firstNameChange",
                        value: e.target.value,
                      })
                    }
                    value={state.firstName.value}
                    placeholder="First Name Here"
                  />
                  {state.firstName.errorMessage && (
                    <p className="error">{state.firstName.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`form-item ${state.lastName.errorMessage ? "field-error" : ""}`}>
                <label>
                  <p>Last Name</p>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({
                        type: "lastNameChange",
                        value: e.target.value,
                      })
                    }
                    value={state.lastName.value}
                    placeholder="Last Name Here"
                  />
                  {state.lastName.errorMessage && (
                    <p className="error">{state.lastName.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className={`form-item ${state.companyName.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Company Name</p>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({ type: "companyChange", value: e.target.value })
                    }
                    value={state.companyName.value}
                    placeholder="Company Name Here"
                  />
                  {state.companyName.errorMessage && (
                    <p className="error">{state.companyName.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`form-item ${state.department.errorMessage ? "field-error" : ""}`}>
                <label>
                  <p>Department</p>
                  <Formm.Select
                    name=""
                    id=""
                    className="form-control"
                    onChange={(e) =>
                      dispatch({
                        type: "departmentChange",
                        value: e.target.value,
                      })
                    }
                    value={state.department.value}
                  >
                    <option value="Please select any">Please select any</option>
                    <option value="Project Management ">Project Management{" "}</option>
                    <option value="Sales ">Sales </option>
                    <option value="Technical">Technical</option>
                    <option value="Operations ">Operations </option>
                    <option value="Customer Support">Customer Support</option>
                  </Formm.Select>
                  {/* <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>    
                    dispatch({
                      type: "departmentChange",
                      value: e.target.value,
                    })
                  }
                /> */}
                  {state.department.errorMessage && (
                    <p className="error">{state.department.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className={`form-item ${state.email.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Company Email ID</p>
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({ type: "emailChange", value: e.target.value })
                    }
                    onBlur={(e) =>
                      dispatch({ type: "validateEmail", value: e.target.value })
                    }
                    value={state.email.value}
                    placeholder="Email ID Here"
                  />
                  {state.email.errorMessage && (
                    <p className="error">{state.email.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className={`form-item ${state.date.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Choose Date</p>
                  <input
                    type="date"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({ type: "dateChange", value: e.target.value })
                    }
                    value={state.date.value}
                    min={`${new Date().getTime()}`}
                  />
                  {state.date.errorMessage && (
                    <p className="error">{state.date.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className={`form-item ${state.message.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Additional Message</p>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({ type: "messageChange", value: e.target.value })
                    }
                    value={state.message.value}
                    placeholder="Write Message Here"
                  ></textarea>
                  {state.message.errorMessage && (
                    <p className="error">{state.message.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="button-group d-flex justify-content-center">
            <div className="button-container">
              <span className="overlay"></span>
              <button className="btn btn-primary" disabled={isPending}>
                {
                  spinner && <Spinner animation="border" size="lg" />
                }
                Request a demo
              </button>
            </div>
          </div>
        </div>
      </form>

      {
        show &&
        <CookiePopup
          showCookiePolicy={show}
          setShowCookiePolicy={setShoww}
        />
      }
      <SuccessMessage
        show={isSuccess}
        setShow={() => setIsSuccess()}
        error={isError}
        messageMain="Form Submitted Successfully"
        messageSub="We will contact you soon"
      />
    </>
  )
}

export default Form
