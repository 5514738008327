import React from "react"
import { Link } from "react-router-dom"
import icon1 from "../../../../Assets/images/teams.svg"
import icon2 from "../../../../Assets/images/careers.svg"
import icon3 from "../../../../Assets/images/contact.svg"
function GetToKnowUs(props) {
  return (
    <section className="section-get-to-know-us">
      <div className="header-box header-box-main text-center">
        <h2> {props.data?.attributes?.Title}</h2>
      </div>
      <div className="container container-small">
        <div className="row gapvetical">
          {props.data?.attributes?.section_contents?.data?.map((item, i) => {
            return (
              <div className="col-sm-12 col-md-6 col-lg-4" key={i}>
                <div className="content-container">
                  <div className="card">
                    <div className="icon-container">
                      <img
                        src={
                          item?.attributes?.SectionImage?.data &&
                          item?.attributes?.SectionImage?.data?.attributes?.url
                        }
                        alt=""
                      />
                    </div>
                    <div className="header-box">
                      <h3>{item?.attributes?.Title}</h3>
                    </div>
                    <div className="text-box">
                      <p>{item?.attributes?.Description}</p>
                    </div>
                    <div className="button-container">
                      <span className="overlay"></span>
                      <Link
                        to={`${item?.attributes?.Url}`}
                        className="btn  btn-transparent btn-dark btn-bold btn-fixed-width"
                      >
                        {item?.attributes?.SubTitle}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default GetToKnowUs
