import React, { useContext, useEffect, useState } from "react"
import StateContext from "../../../StateContext"
import CardOffer from "./CardOffer/CardOffer"
import tag from "../../../Assets/images/check.png"
import CTA2 from "../../CTA2/CTA2"
import Loader from "../../Loader/Loader"
import Page from "../Page"
function PricingComponent() {
  const appState = useContext(StateContext)
  const [loading, setLoading] = useState(true)
  const [pricing, setPricing] = useState()
  const [cta2, setCta2] = useState()

  useEffect(() => {
    if (appState?.tierPage?.data) {
      appState?.tierPage?.data?.map((item, i) => {
        switch (item.id) {
          case 15:
            setPricing(item)
            return
          case 5:
            setCta2(item)
            return
          default:
            return
        }
      })
      setLoading(false)
    }
  }, [appState])
  if (loading) {
    return <Loader />
  }
  return (
    <main className="page-pricing">
      <Page title="Tier">
        <section className="section-pricing">
          <div className="container">
            <div className="header-box text-center"  style={{display:'flex', justifyContent:'center'}}>
              <h1  style={{maxWidth:"100rem"}}>{pricing?.attributes?.Title}</h1>
            </div>
            {/* <div className="controls-box">
            <div className="input-group">
              <label>No Of Users</label>
              <input type="number" className="form-control" />
            </div>
            <div className="input-group">
              <div className="input-group-prepend">
                <p className="text-highlight">Annual</p>
              </div>
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              <div className="input-group-append">
                <p className="text-highlight">Monthly</p>
              </div>
            </div>
          </div> */}
          </div>
        </section>
        <CardOffer data={pricing?.attributes?.section_contents?.data} />

        <CTA2 data={cta2} />
      </Page>
    </main>
  )
}

export default PricingComponent
