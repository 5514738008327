import React, { useEffect, useContext, useState } from "react"
import Page from "../Pages/Page"
import { Link } from "react-router-dom"
import axios from "axios"
import DispatchContext from "../../DispatchContext"
import Loader from "../Loader/Loader"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
function PrivacyPolicyComponent() {
  const appDispatch = useContext(DispatchContext)
  const [data, setData] = useState()
  //FETCH HOMEPAGE DATA
  useEffect(() => {
    const ourRequest = axios.CancelToken.source()
    async function fetch() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/privacy-policies`,
          { cancelToken: ourRequest.token }
        )

        if (response.data) {
          setData(response.data)
          appDispatch({
            type: "FETCH_PRIVACY_POLICY_SUCCESS",
            value: response.data,
          })
        }
      } catch (e) {
        appDispatch({ type: "FETCH_PRIVACY_POLICY_FAILURE" })
      }
    }
    fetch()
    return () => ourRequest.cancel()
  }, [])

  if (!data) {
    return <Loader />
  }

  return (
    <Page title="Privacy Policy">
      <main className="page-privacy-policy policy-page stikcy-header-yes">
        <div className="container">
          <p class="page-navigation">
            <Link to="/">Regulate</Link> <span>/ Privacy Policy</span>
          </p>
        </div>
        <div className="container">
          <div className="policy-container">
            <div className="header-box text-center">
              <h1>{data?.data[0]?.attributes?.Title}</h1>
            </div>
            <div className="text-box">
              <ReactMarkdown
                components={{
                  "h5": ({ node, ...props }) => <u style={{ fontSize: '16px' , textDecoration:"none",color:'#808285'}} {...props} />
                }}                
              >
                {data?.data[0]?.attributes?.Description}
              </ReactMarkdown>
            </div>           
          </div>
        </div>
      </main>
    </Page>
  )
}

export default PrivacyPolicyComponent
