import React from "react"
function Hero(props) {
  return (
    <section
      className="section-hero"
      style={{
        backgroundImage: `url(${
          props.data?.attributes?.Image?.data &&
          props.data?.attributes?.Image?.data[0]?.attributes?.url
        })`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="text-box">
              <h1>{props.data?.attributes?.Title}</h1>
              <div className="button-container">
                <span className="overlay"></span>
                <a href="#team" className="btn">
                  Explore
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
