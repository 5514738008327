import React from "react"
import image from "../../Assets/images/automation.gif"
function Automation(props) {
  return (
    <section className="section-automation section-common">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="image-container">
              <img
                src={props?.data?.attributes?.Image?.data[0]?.attributes?.url}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-5 offset-md-1">
            <div className="content-container">
              <div className="header-box">
                <h1>{props?.data?.attributes?.Title}</h1>
              </div>
              <div className="text-box">
                <p className="subtitle">
                  {props?.data?.attributes?.Description}
                </p>
              </div>
              {/* <div className="button-container">
                <span className="overlay"></span>
                <button className="btn btn-transparent btn-dark">
                  Explore
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Automation
