import React from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

function SecAccordion(props) {
  return (
    <section className="section-accordion">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="content-container text-section">
              <div className="header-box">
                <h1> {props.data?.attributes?.Title}</h1>
              </div>
              <div className="text-box">
                <p>{props.data?.attributes?.Description}</p>
              </div>
            </div>
          </div>



          <div className="col-md-8 offset-md-1">
            <div className="content-container">
              <div className="accordion-custom-header"></div>

              {/* {
                window.innerWidth < 700 && (
                  <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="header-box" style={{ position: 'absolute', top: "83%" }}>
                      <h1 style={{ color: 'white', fontSize: '2.5rem' }}> {props.data?.attributes?.Title}</h1>
                    </div>
                  </div>
                )
              } */}
              <Accordion preExpanded={['a']}>
                {props.data?.attributes?.section_contents?.data?.map((item, index) => (
                  <AccordionItem uuid={index === 0 && "a"}>
                    <AccordionItemHeading className="accordionheader">
                      <AccordionItemButton>
                        {item?.attributes?.Title}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="accordiondescription">
                      {item?.attributes?.Description}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecAccordion
