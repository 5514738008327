import React from "react"
import Form from "./Form"
import Video from "./Video"
function SectionForm(props) {
  return (
    <section className="section-form-video">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5">
            <div className="form-container">
              <Form />
            </div>
          </div>
          <div className="col-xl-6 offset-xl-1 nopadding">
            <Video {...props} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionForm
