import React from "react"
import image1 from "../../../../Assets/images/expertise1.jpg"
import image2 from "../../../../Assets/images/expertise2.jpg"
import image3 from "../../../../Assets/images/expertise3.jpg"
function OurExpertise(props) {
  return (
    <section className="section-our-expertise">
      <div className="container">
        <div className="header-box header-box-main text-center">
          <h1> {props.data?.attributes?.Title}</h1>
        </div>
      </div>
      <div className="container">
        {props.data?.attributes?.section_contents?.data?.map((item, i) => {
          if (i % 2 == 0) {
            return (
              <div className="row" key={i}>
                <div className="col-md-6 col-lg-7">
                  <div className="image-container">
                    <img
                      src={
                        item?.attributes?.SectionImage?.data &&
                        item?.attributes?.SectionImage?.data?.attributes?.url
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="content-container">
                    <div className="header-box">
                      <h1>{item?.attributes?.Title}</h1>
                    </div>
                    <div className="text-box">
                      <p className="subtitle">
                        {item?.attributes?.Description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          } else {
            return (
              <div className="row" key={i}>
                <div className="col-md-6  col-lg-5">
                  <div className="content-container">
                    <div className="header-box">
                      <h1>{item?.attributes?.Title}</h1>
                    </div>
                    <div className="text-box">
                      <p className="subtitle">
                        {item?.attributes?.Description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6  col-lg-7">
                  <div className="image-container">
                    <img
                      src={
                        item?.attributes?.SectionImage?.data &&
                        item?.attributes?.SectionImage?.data?.attributes?.url
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            )
          }
        })}
      </div>
    </section>
  )
}

export default OurExpertise
