import React, { useContext, useState } from "react"
import { useEffect } from "react"
import Form from "react-bootstrap/Form"
import { useImmerReducer } from "use-immer"
import axios from "axios"
import SuccessMessage from "../../../SuccessMessage/SuccessMessage"
import PhoneInput from "react-phone-number-input"
import flags from "react-phone-number-input/flags"
import StateContext from "../../../../StateContext"
import DispatchContext from "../../../../DispatchContext"
import CookiePopup from "../../../CookiePolicy/CookiePopup"
import CookiesJS from 'js-cookie'

function ContactForm() {
  const appState = useContext(StateContext)
  const appDispatch = useContext(DispatchContext)
  const [submitCount, setSubmitCount] = useState(0)
  const [isPending, setIsPending] = useState(false)
  const [isError, setIsError] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [phone, setPhone] = useState("")

  const [show, setShoww] = useState(false)

  const initialState = {
    firstName: {
      label: "First Name",
      value: "",
      errorMessage: "",
    },
    lastName: {
      label: "Last Name",
      value: "",
      errorMessage: "",
    },
    companyName: {
      label: "Company Name",
      value: "",
      errorMessage: "",
    },
    jobTitle: {
      label: "Job Title",
      value: "",
      errorMessage: "",
    },
    phone: {
      label: "Phone",
      value: "",
      errorMessage: "",
    },
    department: {
      label: "Department",
      value: "",
      errorMessage: "",
    },
    email: {
      label: "Email",
      value: "",
      errorMessage: "",
    },
    aboutUs: {
      label: "Hear About Us",
      value: "",
      errorMessage: "",
    },
    message: {
      label: "Additional Message",
      value: "",
      errorMessage: "",
    },
  }

  function ourReducer(draft, action) {
    switch (action.type) {
      case "firstNameChange":
        draft.firstName.errorMessage = ""
        if (/\d/.test(action.value)) {
          draft.firstName.errorMessage = "Name must not contain a number"
        }
        else if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]+/.test(action.value)) {
          draft.firstName.errorMessage = "Special charaters are not allowed"
        }
        draft.firstName.value = action.value
        return
      case "lastNameChange":
        draft.lastName.errorMessage = ""
        if (/\d/.test(action.value)) {
          draft.lastName.errorMessage = "Name must not contain a number"
        }
        else if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`]+/.test(action.value)) {
          draft.lastName.errorMessage = "Special charaters are not allowed"
        }
        draft.lastName.value = action.value
        return
      case "companyChange":
        draft.companyName.errorMessage = ""
        draft.companyName.value = action.value
        return
      case "jobTitleChange":
        draft.jobTitle.errorMessage = ""
        draft.jobTitle.value = action.value
        return
      case "phoneChange":
        draft.phone.errorMessage = ""
        if (!/\d/.test(action.value)) {
          draft.phone.errorMessage = "Phone number must not contain an aphabet"
        }
        if (action?.value?.length > 14) {
          draft.phone.errorMessage =
            "Phone number must not contain more than 10 digits"
        }
        draft.phone.value = action.value
        return
      case "departmentChange":
        draft.department.errorMessage = ""
        if (action.value === "Please select any") {
          draft.department.errorMessage = "Department is required"
        }
        draft.department.value = action.value
        return
      case "emailChange":
        draft.email.errorMessage = ""
        draft.email.value = action.value
        return
      case "validateEmail":
        if (!/^\S+@\S+$/.test(action.value)) {
          draft.email.errorMessage = "Invalid email format"
        }
        return
      case "aboutUsChange":
        draft.aboutUs.errorMessage = ""
        if (action.value === "Please select any") {
          draft.aboutUs.errorMessage = "Hear About Us is required"
        }
        draft.aboutUs.value = action.value
        return
      case "messageChange":
        draft.message.errorMessage = ""
        draft.message.value = action.value
        return
      case "vallidateFields":
        for (const key in draft) {
          if (!draft[key].value) {
            draft[key].errorMessage = `${draft?.[key]?.label} is required`
          }
        }
        if (!phone) {
          draft.phone.errorMessage = "Phone number is required"
        }
        let errorCount = 0
        for (const key in draft) {
          if (draft[key].errorMessage) {
            errorCount++
          }
        }
        //CHECK COOKIE
        // if (!localStorage.getItem("D&HmeasureWebsiteUseCookie")) {
        //   appDispatch({ type: "SHOW_COOKIE_MODAL", value: true })
        //   return
        // }
        if (!errorCount) {
          setSubmitCount(submitCount + 1)
        }

        return
      case "clearForm":
        for (const key in draft) {
          draft[key].value = ""
        }
        setPhone("")
        return

      default:
        return
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState)

  //trigger a dispatch when a phone value changes

  useEffect(() => {
    if (phone) {
      dispatch({ type: "phoneChange", value: phone })
    }
  }, [phone])

  function handleSubmit(e) {
    e.preventDefault()
    if (CookiesJS.get("ALLACCEPTEDCOOKIES") === "true") {
      dispatch({ type: "vallidateFields" })
    }
    else {
      setShoww(true)
    }
  }
  useEffect(() => {
    if (submitCount) {
      setIsPending(true)
      const dataToSend = {
        FirstName: state.firstName.value,
        LastName: state.lastName.value,
        EmailId: state.email.value,
        PhoneNumber: state.phone.value,
        Message: state.message.value,
        CompanyName: state.companyName.value,
        JobTitle: state.jobTitle.value,
        Department: state.department.value,
        HearAboutUs: state.aboutUs.value,
        Message: state.message.value,
      }
      const ourRequest = axios.CancelToken.source()
      async function submit() {
        const config = {
          headers: {
            "content-type": "application/JSON",
          },
        }
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_URL}/api/contact-uses`,
            {
              data: dataToSend,
            },
            config,
            { cancelToken: ourRequest.token }
          )

          if (response.data) {
            setIsPending(false)
            setIsSuccess(true)
            dispatch({ type: "clearForm" })
          }
        } catch (e) {
          setIsPending(false)
          setIsError("Something went wrong, please try again")
        }
      }
      submit()
      return () => ourRequest.cancel()
    }
  }, [submitCount])
  return (
    <div className="contact-form">
      <div className="container">
        <div className="header-box">
          <h3>How Can We Be Of Help?</h3>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div
                className={`form-item ${state.firstName.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>First Name</p>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({
                        type: "firstNameChange",
                        value: e.target.value,
                      })
                    }
                    value={state.firstName.value}
                    placeholder="First Name Here"
                  />
                  {state.firstName.errorMessage && (
                    <p className="error">{state.firstName.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={`form-item ${state.lastName.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Last Name</p>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({
                        type: "lastNameChange",
                        value: e.target.value,
                      })
                    }
                    value={state.lastName.value}
                    placeholder="Last Name Here"
                  />
                  {state.lastName.errorMessage && (
                    <p className="error">{state.lastName.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className={`form-item ${state.companyName.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Company Name</p>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({ type: "companyChange", value: e.target.value })
                    }
                    value={state.companyName.value}
                    placeholder="Company Name Here"
                  />

                  {state.companyName.errorMessage && (
                    <p className="error">{state.companyName.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={`form-item ${state.jobTitle.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Job Title</p>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({
                        type: "jobTitleChange",
                        value: e.target.value,
                      })
                    }
                    value={state.jobTitle.value}
                    placeholder="Job Title Here"
                  />
                  {state.jobTitle.errorMessage && (
                    <p className="error">{state.jobTitle.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className={`form-item ${state.phone.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Phone Number</p>
                  <PhoneInput
                    placeholder="Enter phone number"
                    flags={flags}
                    value={phone}
                    onChange={setPhone}
                    defaultCountry="US"
                  />
                  {state.phone.errorMessage && (
                    <p className="error">{state.phone.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={`form-item ${state.department.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Which Department Would You Like To Contact?</p>
                  <Form.Select
                    aria-label="Default select example"
                    value=""
                    className="form-control"
                    onChange={(e) =>
                      dispatch({
                        type: "departmentChange",
                        value: e.target.value,
                      })
                    }
                    value={state.department.value}>
                    <option value="Please select any">Please select any</option>
                    <option value="Project Management ">Project Management{" "}</option>
                    <option value="Sales ">Sales </option>
                    <option value="Technical">Technical</option>
                    <option value="Operations ">Operations </option>
                    <option value="Customer Support">Customer Support</option>
                  </Form.Select>
                  {state.department.errorMessage && (
                    <p className="error">{state.department.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div
                className={`form-item ${state.email.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>Email</p>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({ type: "emailChange", value: e.target.value })
                    }
                    onBlur={(e) =>
                      dispatch({ type: "validateEmail", value: e.target.value })
                    }
                    value={state.email.value}
                    placeholder="Email ID Here"
                  />
                  {state.email.errorMessage && (
                    <p className="error">{state.email.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={`form-item ${state.aboutUs.errorMessage ? "field-error" : ""
                  }`}
              >
                <label>
                  <p>How Did You Hear About Us?</p>
                  <Form.Select
                    aria-label="Default select example"
                    value=""
                    className="form-control"
                    onChange={(e) =>
                      dispatch({ type: "aboutUsChange", value: e.target.value })
                    }
                    value={state.aboutUs.value}
                  >
                    <option value="Please select any">Please select any</option>
                    <option value="Social media (Twitter, Facebook, etc.)">
                      Social media (Twitter, Facebook, etc.)
                    </option>
                    <option value="Search engine (Google, Yahoo, etc.)">
                      Search engine (Google, Yahoo, etc.)
                    </option>
                    <option value="Email">Email</option>
                    <option value="Advertisements">Advertisements</option>
                    <option value="Heard on TV, Radio, or Podcast app">
                      Heard on TV, Radio, or Podcast app
                    </option>
                    <option value="Friend or colleague">
                      Friend or colleague
                    </option>
                    <option value="Advertisements">Advertisements</option>
                    <option value="Blog or publication">
                      Blog or publication
                    </option>
                    <option value="Word of mouth">Word of mouth</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                  {state.aboutUs.errorMessage && (
                    <p className="error">{state.aboutUs.errorMessage}</p>
                  )}
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <label>
                <div
                  className={`form-item ${state.message.errorMessage ? "field-error" : ""
                    }`}
                >
                  <p>Additional Message</p>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className="form-control"
                    onChange={(e) =>
                      dispatch({ type: "messageChange", value: e.target.value })
                    }
                    value={state.message.value}
                    placeholder="Write Message Here"
                  ></textarea>
                </div>
                {state.message.errorMessage && (
                  <p className="error">{state.message.errorMessage}</p>
                )}
              </label>
            </div>
          </div>
          <div className="btn-group d-flex justify-content-end">
            <div className="button-container">
              <span className="overlay"></span>
              <button className="btn btn-primary" disabled={isPending}>
                {!isPending ? "Submit" : "Submitting"}
              </button>
            </div>
          </div>
        </form>
      </div>

      {
        show &&
        <CookiePopup
          showCookiePolicy={show}
          setShowCookiePolicy={setShoww}
        />
      }
      <SuccessMessage
        show={isSuccess}
        setShow={() => setIsSuccess()}
        error={isError}
        messageMain="Form Submitted Successfully"
        messageSub="We will contact you soon"
      />
    </div>
  )
}

export default ContactForm
