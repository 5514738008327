import React from "react"
import image from "../../../../Assets/images/key.jpg"
function WhoWeAre(props) {
  return (
    <section className="section-who-we-are">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="content-container">
              <div className="header-box">
                <h1> {props.data?.attributes?.Title}</h1>
                <div className="text-box">
                  <p className="subtitle">
                    {props.data?.attributes?.Description}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="image-container">
              <img
                src={
                  props.data?.attributes?.Image?.data &&
                  props.data?.attributes?.Image?.data[0]?.attributes?.url
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoWeAre
